import styled from "styled-components";
import { Row } from "../custom/CustomLayout";
import { TextBox } from "../SText";

const MobileHamburger = styled.button`
  display: none;
  height: 44px;
  width: 44px;
  background-color: var(--c-hover);
  border-radius: 999px;

  margin: 8px 0;

  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    display: flex;
    background-color: var(--c-gray-700);
  }
  @media (max-width: 360px) {
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
  }
`;

const HeaderLayout = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 0;
  z-index: 9999;
  background: linear-gradient(180deg, #101119 50%, rgba(16, 17, 25, 0) 100%);

  @media (max-width: 700px) {
    background: transparent;
    width: fit-content;
    padding: 0;
  }
`;
const MainMenu = styled(Row)`
  gap: 8px;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 999px;

  &:hover,
  &.active {
    background: var(--c-gray-700);
  }
`;

const QuickMenu = styled(Row)``;

const HeaderBetween = styled(Row)`
  align-items: center;
  justify-content: space-between;

  padding: 8px;
  width: 100%;

  border-radius: 999px;
  background: var(--c-gray-800);

  @media (max-width: 1100px) {
    justify-content: flex-end;
  }

  @media (max-width: 700px) {
    width: fit-content;
    background: var(--c-gray-700);
  }
`;

const HD = styled(Row)`
  width: 100%;
  max-width: 1574px;
  margin: 0 auto;

  position: sticky;
  right: 0px;
  top: 0px;
  z-index: 9999;

  @media (max-width: 700px) {
    justify-content: space-between;
    align-items: center;
    background-color: var(--c-gray-800);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 16px;
    top: 0;
  }

  @media (max-width: 360px) {
  }
`;

const AssetsCash = styled(TextBox)`
  @media (max-width: 440px) {
    font-size: var(--s-caption);
    font-family: var(--f-captionB);
    line-height: var(--l-caption);
  }
`;

const MyCashBox = styled(Row)`
  @media (max-width: 440px) {
    padding: 0 12px;
  }
`;

export { HeaderLayout, MainMenu, MyCashBox, QuickMenu, AssetsCash, HD, HeaderBetween, MobileHamburger };
