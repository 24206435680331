import axios from "axios";
import { LoginCheck } from "util/useStateCheck";

// sotre
let loginInfo = LoginCheck();

export const setLoginInfo = (info) => {
  loginInfo = info;
};

const tokenApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

tokenApi.interceptors.request.use(async (config) => {
  if (config.checkAccToken) {
    loginInfo = LoginCheck();
    config.headers.Authorization = `Bearer ${loginInfo.accessToken}`;
    return config;
  } else if (config.checkRefToken) {
    loginInfo = LoginCheck();
    config.headers.Authorization = `Bearer ${loginInfo.refreshToken}`;
    return config;
  }

  return config;
});

export default tokenApi;
