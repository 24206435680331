import { FlexBox } from "assets/Styles/SLayout";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const Column = styled(FlexBox)`
  flex-direction: column;
`;
const Row = styled(FlexBox)`
  &.not-login {
    opacity: 0.4;
  }
`;

const Between = styled(FlexBox)`
  align-items: ${(props) => props.$align ?? "center"};
  justify-content: space-between;
`;

const Center = styled(FlexBox)`
  align-items: center;
  justify-content: center;
`;

const createHoverBox = ({ $width, $height, $radius, $backColor }) => css`
  width: ${$width};
  height: ${$height};

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${$backColor};
    border-radius: ${$radius};
  }
`;

const HoverBox = styled.div`
  ${createHoverBox}
`;

const PurpleGBox = styled(FlexBox)`
  background: var(--c-pGradient);
  border-radius: 999px;

  cursor: pointer;
`;

const GlassBox = styled(FlexBox)`
  background-color: var(--c-gray-700);
  border-radius: 30px;
  height: 44px;
  @media (max-width: 700px) {
    background-color: var(--c-gray-600);
  }

  @media (max-width: 360px) {
    background-color: var(--c-gray-600);
    height: 32px;
  }
`;

const ContentBox = styled(FlexBox)``;
const ScrollBox = styled(FlexBox)`
  display: block;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
`;
const RowWrap = styled(FlexBox)`
  flex-wrap: wrap;
`;

const Thumbnail = styled.img`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$radius};
  object-fit: ${(props) => props.$fit};

  &.imgclearly {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    -ms-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    object-fit: contain;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(179px, 1fr)); // 최소 150px을 유지하면서 가능한 한 많은 열을 생성
  grid-gap: 24px 12px;
  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(104px, 1fr)); // 최소 150px을 유지하면서 가능한 한 많은 열을 생성
    grid-gap: 16px 8px;
  }
`;

const RowLink = styled(Link)`
  display: flex;
  align-items: ${(props) => props.$align ?? "center"};
  gap: ${(props) => props.$gap};
  width: ${(props) => props.$width};
`;

const Linked = styled(Link)``;

export {
  Row,
  Grid,
  Column,
  Between,
  Center,
  HoverBox,
  ContentBox,
  GlassBox,
  ScrollBox,
  PurpleGBox,
  RowWrap,
  Thumbnail,
  RowLink,
  Linked,
};
