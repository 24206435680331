import * as  TYPE from "./actionTypes"

const initialState = {
  alarm: 0,
  total_unread_messages: 0,
  money: 0,
  point: 0,
  state: ""
}

const Client = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.UPDATE:
      state = {
        alarm: action.alarm,
        total_unread_messages: action.total_unread_messages,
        money: action.money,
        point: action.point,
        state: action.state
      }
      break;
    
    case TYPE.UNREAD_MESSAGES:
      return {
        ...state,
        total_unread_messages: action.total_unread_messages,
      }

    case TYPE.UPDATE_MONEY:
      return {
        ...state,
        money: action.money,
      }
      
    case TYPE.ALARM:
      return {
        ...state,
        alarm: action.alarm
      };
    
    case TYPE.INITIAL_STATE:
      return initialState;

    default:
      state = state
      break
  }
  return state
}

export default Client
