import axios from "axios";
import store from '../store';

import { t } from "i18next";
import { login, clienInitialState, dataInitalState } from "../store/actions";
import { LoginCheck } from "util/useStateCheck";
import { SwalAlertCallBack } from "../util/SwalAlert"
import { TokenSubstitution, isTokenValid } from "util/FormUtils";

// sotre
let loginInfo = LoginCheck();
// 리플레쉬 여부
let isRefreshing = false;
// 요청 큐
let pendingRequests = [];

export const setLoginInfo = (info) => {
  loginInfo = info;
};

const reduxDeleteData = () => {
  store.dispatch(clienInitialState());
  store.dispatch(dataInitalState());
}

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(async (config) => {

  if (loginInfo) {
    const { exp } = TokenSubstitution(loginInfo.accessToken);
    if (!isTokenValid(exp) && !isRefreshing) {
      isRefreshing = true;
      await RefreshToken();
    }

    config.headers.Authorization = `Bearer ${loginInfo.accessToken}`;
  }

  return config;
});

api.interceptors.response.use((response) => { return response },
  (error) => {
    const { response } = error;
    // 토큰 만료
    if (response.status === 401) {
      // 아직 재발급 받지 못했다면 요청 큐를 쌓는다
      if (!loginInfo) {
        localStorage.clear();
        window.location.href = `${window.location.origin}/`
      }
      if (isRefreshing) {
        return new Promise((resolve) => {
          pendingRequests.push(() => {
            resolve(api(error.config))
          });
        })
        // 재발급 받았다면 재요청
      } else {
        return new Promise((resolve) => {
          resolve(api(error.config))
        });
      }
    }
    // 권한 부족
    if (response.status === 403) {
      reduxDeleteData();
      return window.location.href = `${window.location.origin}/`
    }
    // 중복 로그인
    if (response.status === 409) {
      return SwalAlertCallBack('error', t("myMenu.logout"), `<p>${t("alert.duplicate_login")}</p>`, () => {
        reduxDeleteData();
        window.location.href = `${window.location.origin}/`
      });
    }

    return Promise.reject(error);
  }
);
/**
 * 토큰 갱신
 */
const RefreshToken = async () => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/auth-token/refresh-token`, {},
      {
        headers: {
          Authorization: `Bearer ${loginInfo.refreshToken}`
        }
      }
    );
    // 토큰 갱신
    const { accessToken, refreshToken } = result.data.content;
    await store.dispatch(login({ "accessToken": accessToken, "refreshToken": refreshToken }));
    // 값 초기화 및 요청 큐 호출
    isRefreshing = false;
    loginInfo = LoginCheck();
    pendingRequests.forEach((CallBack) => CallBack());
    pendingRequests = [];
  } catch (error) {
    console.error(error)
    localStorage.clear();
    window.location.href = `${window.location.origin}/`
  }
}

export default api;
