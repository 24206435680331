import React, { createContext, useCallback, useContext, useState } from "react";
import styled from "styled-components";

import { TextBox } from "assets/Styles/SText";
import { ActionBtn } from "assets/Styles/SButton";
import { Dimmed, PositionBox } from "assets/Styles/SLayout";
import { HeaderCloseIcon } from "assets/components/IconComponent";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";

import { useCommonState } from "./useContext";
import { useMedia } from "util/useMedia";

const PopupContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth || "320px"};
  height: ${({ $height }) => $height};

  @media (max-width: 340px) {
    padding: 0 8px;
  }
  ${({ $customStyle }) => $customStyle}
`;

const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  z-index: 9999;
  padding: 20px;
  border-radius: 24px;
  background-color: var(--c-gray-900);

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const PopupWrap = styled(Column)`
  background-color: var(--c-gray-900);
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth || "320px"};
  height: ${({ $height }) => $height};
  padding: 0 24px 24px;
  border-radius: ${({ $radius }) => $radius || "16px"};

  ${({ $customStyle }) => $customStyle}
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: ${({ $pad }) => ($pad ? $pad : "20px 0")};
  background-color: var(--c-gray-900);

  ${({ $customStyle }) => $customStyle}
`;

const PopupContext = createContext();
const AlertContext = createContext();
const ConfirmContext = createContext();

export const usePopup = () => useContext(PopupContext);
export const useAlert = () => useContext(AlertContext);
export const useConfirm = () => useContext(ConfirmContext);

export const WindowProvider = ({ children }) => {
  const { isMaxMiddleM } = useMedia();
  const { setInputError } = useCommonState();

  const [showPopup, setShowPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMain, setConfirmMain] = useState(false);

  const [popups, setPopups] = useState([]);

  const [subNavigate, setSubNavigate] = useState("");
  const [mainNavigate, setMainNavigate] = useState("");

  const [areaTitle, setAreaTitle] = useState("");

  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [subBtnMsg, setSubBtnMsg] = useState("");
  const [mainBtnMsg, setMainBtnMsg] = useState("");

  const [func, setFunc] = useState(() => null);


  const openAlert = ({ message, message2, title, mainBtn, Func }) => {
    setShowAlert(true);
    setAreaTitle(title);

    setMessage(message);
    setMessage2(message2);
    setMainBtnMsg(mainBtn);
    setFunc(() => Func || null);

    document.body.classList.add("scroll-lock");
  };

  const openConfirm = ({ message, title, subBtn, mainBtn, subUrl, mainUrl, Func, isRed }) => {
    setShowConfirm(true);
    setAreaTitle(title);

    setMessage(message);
    setSubBtnMsg(subBtn || subBtnMsg);
    setMainBtnMsg(mainBtn || mainBtnMsg);

    setSubNavigate(subUrl);
    setMainNavigate(mainUrl);
    setConfirmMain(isRed);

    setFunc(() => Func || null);

    document.body.classList.add("scroll-lock");
  };

  const openPopup = (popupConfig) => {
    setShowPopup(true);
    setPopups((currentPopups) => [...currentPopups, { ...popupConfig, id: Math.random() }]);
    document.body.classList.add("scroll-lock");
    document.activeElement.blur();
  };

  const closeAlert = () => {
    if (func) {
      setShowAlert(false);
      func();
    }
    setShowAlert(false);
    document.body.classList.remove("scroll-lock");
  };
  const closeConfirm = (typeNo) => {
    if (func !== null && typeNo === 2) {
      func();
      setShowConfirm(false);
    } else {
      setShowConfirm(false);
    }
    document.body.classList.remove("scroll-lock");
  };

  const closePopup = () => {
    if (popups.length > 0) {
      const currentPopup = popups[popups.length - 1];

      if (currentPopup.onClose) {
        currentPopup.onClose();
      }
    }
    setInputError({});
    setPopups((currentPopups) => currentPopups.slice(0, currentPopups.length - 1));
    document.body.classList.remove("scroll-lock");
  };

  const getResponsiveStyles = useCallback(
    (props) => {
      if (props.isResponsive && isMaxMiddleM) {
        return {
          maxWidth: "100%",
          height: "100vh",
          radius: "0px",
          pad: "24px 0",
          conPad: "0",
        };
      }
      return {
        maxWidth: props.$maxWidth || "400px",
        height: props.$height,
        radius: props.$radius || "16px",
        pad: props.$pad || "20px 0",
        conPad: props.$conPad || "0",
      };
    },
    [isMaxMiddleM]
  );

  const alertComponent = showAlert && (
    <Dimmed>
      <Box>
        <TextBox as="h2" $pad="0 0 8px" $class={["title", "white"]}>
          {areaTitle}
        </TextBox>
        {message && <TextBox as="p">{message}</TextBox>}
        {message2 && <TextBox as="p">{message2}</TextBox>}
        <Row $width="100%" $pad="20px 0 0">
          <ActionBtn onClick={closeAlert} $pad="10px 0" $size="var(--s-subB)" $width="100%" $radius="12px">
            {mainBtnMsg || "확인"}
          </ActionBtn>
        </Row>
      </Box>
    </Dimmed>
  );

  const confirmComponent = showConfirm && (
    <Dimmed>
      <Box>
        {areaTitle && <TextBox $class={["white", "title"]}>{areaTitle}</TextBox>}
        {message && <TextBox $pad="8px 0 0">{message}</TextBox>}
        <Row $width="100%" $gap="8px" $pad="20px 0 0">
          <ActionBtn
            {...(subNavigate && { as: "a" })}
            {...(subNavigate && { href: subNavigate })}
            onClick={() => closeConfirm()}
            $pad="10px 0"
            $width="100%"
            $radius="12px"
          >
            {subBtnMsg || "확인"}
          </ActionBtn>
          <ActionBtn
            {...(mainNavigate && { as: "a" })}
            {...(mainNavigate && { href: mainNavigate })}
            onClick={() => closeConfirm(2)}
            $pad="10px 0"
            $width="100%"
            $radius="12px"
            $backColor={confirmMain ? "var(--c-red)" : "var(--c-pGradient)"}
          >
            {mainBtnMsg || "삭제"}
          </ActionBtn>
        </Row>
      </Box>
    </Dimmed>
  );

  const popupComponent = showPopup ? (
    <>
      {popups.map((popupProps) => {
        const { maxWidth, height, radius, pad, conPad } = getResponsiveStyles(popupProps);
        return (
          <Dimmed key={popupProps.id}>
            <PopupContainer $maxWidth={maxWidth} $height={height}>
              <PositionBox $pad={conPad} $position="relative">
                <PopupWrap $maxWidth={maxWidth} $height={height} $radius={radius}>
                  <Sticky $pad={pad ? pad : "20px 0"}>
                    <Between>
                      <TextBox $class={["title", "white"]}>{popupProps.title}</TextBox>
                      <HeaderCloseIcon onClick={closePopup} width="24" height="24" fill="var(--c-gray-300)" />
                    </Between>
                  </Sticky>
                  {typeof popupProps.content === "function"
                    ? React.createElement(popupProps.content, {
                      ...popupProps.props,
                      closePopup,
                    })
                    : popupProps.content}
                </PopupWrap>
              </PositionBox>
            </PopupContainer>
          </Dimmed>
        );
      })}
    </>
  ) : null;

  return (
    <AlertContext.Provider value={{ openAlert }}>
      <ConfirmContext.Provider value={{ openConfirm }}>
        <PopupContext.Provider value={{ openPopup, closePopup }}>
            {children}
            {popupComponent}
            {alertComponent}
            {confirmComponent}
        </PopupContext.Provider>
      </ConfirmContext.Provider>
    </AlertContext.Provider>
  );
};
