import React from "react";
import { InputCon } from "assets/Styles/SInput";
import { Between, Row } from "assets/Styles/custom/CustomLayout";
import { ClearIcon, SearchIcon } from "assets/components/IconComponent";
import { InputForm } from "data/useForm";

const SearchCompo = ({ apiFunc, type, page, getData, radius, conWidth, conColor, placeColor, pad, ...getValue }) => {
  const reset = () => {
    if (type === "each") {
      getValue.setFormValue((prevState) => ({
        ...prevState,
        value: "",
      }));
    } else {
      getValue.searchReset();
      if (getValue.searchReset) {
        apiFunc();
        getData.setExtendData({
          ...getData.extendData,
          pagingData: {
            ...getData.extendData.pagingData,
            countPerPage: page,
          },
        });
      }
    }
  };

  return (
    <InputCon
      $width={conWidth}
      $radius={radius ? radius : "50px"}
      $backColor={conColor ? conColor : "var(--c-gray-900)"}
    >
      <Between $gap="12px" $pad={pad ? pad : "12px 20px"}>
        <Row $gap="12px" $align="center" $width="100%">
          <SearchIcon />
          <InputForm
            name={getValue.name}
            placeColor={placeColor ? placeColor : "var(--c-gray-400)"}
            {...getValue}
            placeholder={getValue.placeholder}
          />
        </Row>
        {getValue.formValue.value?.length !== 0 && <ClearIcon onClick={reset} width="20" height="20" />}
      </Between>
    </InputCon>
  );
};

export default SearchCompo;
