import { format, isValid, parse } from "date-fns";

/**
 * Date type -> String date
 * @param {
 * } date
 * @returns
 */
export const dateFormat = (date) => {
  return date ? format(new Date(date), "yyyy-MM-dd") : "-";
};

export const dateCommaFormat = (date) => {
  if (!date) return "-";

  let parsedDate;
  let dateString = date;

  if (typeof date === "object" && date !== null && "yyyymm" in date) {
    dateString = date.yyyymm;
  }

  if (typeof dateString === "string") {
    if (dateString.length === 8) {
      parsedDate = parse(dateString, "yyyyMMdd", new Date());
    } else if (dateString.length === 6) {
      parsedDate = parse(dateString, "yyyyMM", new Date());
    } else {
      parsedDate = new Date(dateString);
    }
  } else {
    parsedDate = new Date(dateString);
  }

  if (!isValid(parsedDate)) return "-";

  return typeof dateString === "string" && dateString.length === 6
    ? format(parsedDate, "yyyy. MM")
    : format(parsedDate, "yyyy. MM. dd");
};

export const dateTimeFormat = (date) => {
  return date ? format(new Date(date), "yyyy. MM. dd HH:mm") : "-";
};

export const formatKRDate = (date, includeDay = false) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  
  return `${year}년 ${month}월 ${includeDay ? `${day}일` : ''}`;
};