const errorFlagEnum = {
  LOGOUT: "logout",
};

const validationTypeEnum = {
  ENGLISH: "english",
  NUMBER: "number",
  ENGLISH_AND_NUMBER: "englishAndNumber",
  DECIMAL: "decimal",
};
export { errorFlagEnum, validationTypeEnum };
