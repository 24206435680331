import React from "react";

import MyMenu from "../layout/MyMenu";
import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import useToast from "components/hooks/useToast";

import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";

import { useMedia } from "util/useMedia";
import { InputForm } from "data/useForm";
import { valueReplace } from "util/Inputmask";
import { getErrorClass } from "util/FormStateUtil";
import { useCommonState } from "context/useContext";

import { InputCon } from "assets/Styles/SInput";
import { TextBox, WarningMsg } from "assets/Styles/SText";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";
import { CheckIcon, PointIcon, ResetIcon, WarningIcon } from "assets/components/IconComponent";
import { CashPlusBtn, ContentSwap, P_GradientBtn, ResetBtn } from "assets/Styles/SButton";
import { GrayCon, Empty, FlexBox, MyPageLayout, WarningBox } from "assets/Styles/SLayout";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import { t } from "i18next";
import { useAlert } from "context/useWindow";
import { Link } from 'react-router-dom';

const PointConversion = () => {
  const dispatch = useDispatch();
  const { openAlert } = useAlert();
  const { isMiddleD, isMaxMiddleM } = useMedia();
  const { inputError, setInputError, setLoading } = useCommonState();
  const { Toast, toastOpen, openToast } = useToast();
  
  const cashPlus = (money) => {
    getValue.setFormValue((prev) => {
      let currentCash = typeof prev.amount === "string" ? valueReplace(prev.amount) : prev.amount;

      if (isNaN(currentCash)) {
        currentCash = 0;
      }

      const updatedCash = currentCash + money;

      return {
        ...prev,
        amount: updatedCash.toString(),
      };
    });
  };

  const getPointInfo = () => {
    setInputError('')
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/point/info";

    api
      .get(apiUrl)
      .then((res) => {
        getPoint.setData(res.data.content);
        setLoading(false);
        getPoint.setError("");
      })
      .catch((err) => {
        console.error(err);
        getPoint.setError(true);
        setLoading(false);
        getPoint.setData([]);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const postDeposit = () => {
    dispatch(loadingStatus(true));

    const minMoney = getPoint.data.point_to_cash_min;
    const maxMoney = getPoint.data.point_to_cash_max;

    const formValued = getValue.formValue;

    const cashValue = formValued.amount ? valueReplace(formValued.amount.toString()) : 0;

    if (cashValue >= minMoney && cashValue <= maxMoney && getData.point >= cashValue) {
      const apiUrl = "/v1/point";
      api
        .post(apiUrl, {
          amount: Number(formValued.amount),
        })
        .then((res) => {
          openToast("/my.point");
        })
        .catch((err) => {
          console.error(err);
          // 운영시간 벗어남
          if (err.response.data.error == 'checkTime') {
            // 팝업 후 홈으로
            openAlert({
              message: t("alert.check_time"),
              Func: () => {
                window.location.reload();
              },
            });
          }
          else {
            setInputError({
              amount: t("error.impossible_point_conversion"),
            });
          }
        }).finally(() => {
          dispatch(loadingStatus(false));
        });
    } else {
      setInputError({
        amount: t("error.impossible_point_conversion"),
      });
      dispatch(loadingStatus(false));
    }
  };

  const getPoint = useGetData({
    firstInit: true,
    api: getPointInfo,
  });

  const getData = getPoint.data;
  const getValue = useFormData({
    amount: "",
  });
  if (!getData) return;
  console.log(getData);

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="24px">
          <Row $pad="6px" $radius="30px" $backColor="var(--c-gray-900)">
            <ContentSwap className="active">{t("common.point_transfer")}</ContentSwap>
            <ContentSwap as={Link} to="/my.point">{t("sectionTitle.point_transfer_history")}</ContentSwap>
          </Row>

          {(getData?.current_time_in_range === "Y" || getData?.point_to_cash_time_yn == "N") && !getData.isExistPointHistory ?
            // 포인트 전환 페이지
            (
              <>
                <Column $gap="10px">
                  <InputCon $pad="12px 16px" $radius="12px">
                    <Between>
                      <TextBox $class="gray300">{t("common.point_balance")}</TextBox>
                      <Row $gap="6px">
                        <TextBox $class={["subB", "gray300"]}>{getData.point?.toLocaleString()}</TextBox>
                        <Empty $width="20px" $height="20px">
                          <PointIcon width="20" height="20" />
                        </Empty>
                      </Row>
                    </Between>
                  </InputCon>
                  <InputCon $pad="12px 16px" $radius="12px" className={getErrorClass(inputError, "amount")}>
                    <Between>
                      <TextBox $class="gray300" $white="nowrap" $width="100px">
                        {t("common.point_transfer_amount")}
                      </TextBox>
                      <Row $gap="6px" $width="100%">
                        <InputForm
                          name="amount"
                          tAlign="right"
                          placeholder="0"
                          font="var(--f-subB)"
                          maskType="comma_number"
                          placeColor="var(--c-white)"
                          backColor="var(--c-gray-700)"
                          errors={inputError}
                          setErrors={setInputError}
                          {...getValue}
                        />
                        <Empty $width="20px" $height="20px">
                          <PointIcon width="20" height="20" />
                        </Empty>
                      </Row>
                    </Between>
                  </InputCon>
                  <Row $gap="10px">
                    <CashPlusBtn onClick={() => cashPlus(10000)}>+10,000</CashPlusBtn>
                    <CashPlusBtn onClick={() => cashPlus(100000)}>+100,000</CashPlusBtn>
                    <CashPlusBtn onClick={() => cashPlus(1000000)}>+1,000,000</CashPlusBtn>
                    <ResetBtn
                      onClick={() => {
                        getValue.resetForm();
                        setInputError({});
                      }}
                    >
                      <ResetIcon width="20" height="20" />
                    </ResetBtn>
                  </Row>
                  <Error name={"amount"} errors={inputError}></Error>

                  {getData.point_to_cash_min !== 0 && getData.point_to_cash_max !== 0 && (
                    <TextBox $class={isMaxMiddleM ? ["caption", "gray300"] : "gray300"}>
                      {`${t("infoMsg.amount_range_point", {
                        action: t("common.point"),
                        min: getData?.point_to_cash_min?.toLocaleString(),
                        max: getData?.point_to_cash_max?.toLocaleString(),
                      })}`}
                    </TextBox>
                  )}
                  {getData?.point_to_cash_unit !== 0 && (
                    <TextBox $class={isMaxMiddleM ? ["caption", "gray300"] : "gray300"}>
                      {`${t("infoMsg.point_unit", {
                        unit: getData?.point_to_cash_unit,
                      })}`}
                    </TextBox>
                  )}
                </Column>
                <>
                  {getData.point_to_cash_time_yn == "Y" ? (
                    <WarningBox $pad="14px 20px" $radius="12px">
                      <Column $gap="12px">
                        <Row $gap="8px" $align="flex-start">
                          <Row $width="18px" $height="18px">
                            <WarningIcon width="18" height="18" />
                          </Row>
                          <WarningMsg $font="var(--f-subB)" $size="var(--s-sub)" $padT="0">
                            {`${t("infoMsg.inspection_time_message_detail2", {
                              days: getData?.point_to_cash_days_text,
                              startTime: getData?.point_to_cash_start_time,
                              endTime: getData?.point_to_cash_end_time,
                              action: t("common.point_transfer"),
                            })}`}
                          </WarningMsg>
                        </Row>
                      </Column>
                    </WarningBox>
                  ) : null}
                </>
                <P_GradientBtn onClick={postDeposit} $pad="12px 0" $width="100%" $radius="12px">
                  {t("button.point_transfer")}
                </P_GradientBtn>
              </>
            ) :
            // 경고 페이지
            (
              <GrayCon>
                <Column $gap="8px" $align="center">
                  <WarningIcon width="24" height="24" />
                  {getData.isExistPointHistory ? (
                    <Empty>
                      <TextBox $width="100%" $tAlign="center" $class={["white", "title"]}>
                        {t("infoMsg.request_in_progress")}
                      </TextBox>
                      <TextBox $width="100%" $tAlign="center" $class={["white", "title"]}>
                        {t("infoMsg.request_wait_message", {
                          wait: getData?.point_to_wait,
                        })}
                      </TextBox>
                    </Empty>
                  ) : (
                    <>
                      <TextBox $class={["white", "title"]}>
                        {t("infoMsg.inspection_time_message", {
                          action: t("common.point_transfer"),
                        })}
                      </TextBox>
                      <TextBox $class="subB">
                        {`${t("infoMsg.inspection_time")} : ${getData?.point_to_cash_days_text} ${getData?.point_to_cash_start_time
                          }~${getData?.point_to_cash_end_time}`}
                      </TextBox>
                    </>
                  )}
                </Column>
              </GrayCon>
            )}
        </Column>

        {toastOpen && (
          <Toast
            Icon={<CheckIcon />}
            toastText={t("toast.request_success", {
              action: t("common.point_transfer"),
            })}
          />
        )}
      </MyPageLayout>
    </FlexBox>
  );
};

export default PointConversion;
