import { initReactI18next } from "react-i18next";
import KO from "locales/ko/translator.json";
import EN from "locales/en/translator.json";
import i18n from "i18next";

// 다국어 지원을 초기화하고 번역 리소스를 설정합니다.
i18n.use(initReactI18next).init({
  resources: {
    ko: { translation: KO },
    en: { translation: EN },
  },
  lng: "ko", // 기본 설정 언어
  fallbackLng: "en", // 번역 파일에서 찾을 수 없는 경우 기본 언어
  interpolation: {
    escapeValue: false, // React는 이미 XSS를 방지합니다.
  },
  react: {
    bindI18n: "languageChanged",
    bindStore: false,
    nsMode: "default",
  },
});

// 브라우저에 기록된 언어가 있으면 해당 언어를 불러온다.
let language = localStorage.getItem("language");
if (language !== null) i18n.changeLanguage(language);

// 언어 변경 시 로컬 스토리지에 언어 설정을 저장합니다.
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng);
});

// 국가 코드에 따라 언어를 변경하는 함수
export function changeLanguage(language) {
  i18n.changeLanguage(language);
}

export default i18n;
