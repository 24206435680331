import React, { useState } from "react";
import { t } from "i18next";

import api from "interceptor/api";
import MyMenu from "./layout/MyMenu";
import useGetData from "data/useGetData";

import useGetEnum from "enum/useGetEnum";
import { useMedia } from "util/useMedia";
import { SelectForm } from "data/useForm";
import { dateTimeFormat } from "util/DateUtil";
import { useCommonState } from "context/useContext";

import { TextBox, WarningMsg } from "assets/Styles/SText";
import { CheckIcon, DuplicationIcon, WarningIcon } from "assets/components/IconComponent";
import { FlexBox, MyPageLayout, WarningBox } from "assets/Styles/SLayout";
import { Column, Row, ScrollBox } from "assets/Styles/custom/CustomLayout";
import { TableHeader, Table, TableDeps, TableRow } from "assets/Styles/STable";

import { useDispatch } from "react-redux";
import { loadingStatus } from "../../store/actions";

import Pagination from "components/hooks/Pagination";
import NoListLayout from "components/common/NoListLayout";
import useToast from "components/hooks/useToast";

const MyBettingList = () => {
  const enums = useGetEnum();
  const dispatch = useDispatch();

  const { isMiddleD, isMaxMiddleM, isScroll } = useMedia();
  const { setLoading } = useCommonState();

  const { Toast, toastOpen, openToast } = useToast();
  const [toastMsg, setToastMsg] = useState("");
  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setToastMsg(t("toast.copy_success"));
        openToast();
      })
      .catch((err) => { });
  };

  const getBetLog = () => {
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/account/bet/log";

    api
      .get(apiUrl, {
        params: {
          ...getBetting.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getBetting.setError("");
        getBetting.setData(res.data.content);

        if (res.data.pageHelperDto) {
          getBetting.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        console.error(err);
        getBetting.setError(true);
        setLoading(false);
        getBetting.setData([]);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const getBetting = useGetData({
    firstInit: true,
    api: getBetLog,
    pageSize: "10",
  });

  const getData = getBetting.data;

  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout $dis="flex" $jus="flex-end" $overX="hidden" $maxWidth="100%">
        <Column $gap="32px" $width="100%">
          <Row $gap="12px" $width="100%">
            <SelectForm
              placeholder={t("placeholder.all_state")}
              options={enums.bettingState}
              name={"transaction_type"}
              conWidth={isMaxMiddleM && "100%"}
              {...getBetting}
            />
            <SelectForm
              placeholder={t("placeholder.today")}
              name={"time_type"}
              options={enums.bettingPeriod}
              conWidth={isMaxMiddleM && "100%"}
              {...getBetting}
            />
          </Row>

          <WarningBox $pad="14px 20px" $radius="12px">
            <Row $gap="8px" $align="flex-start">
              <Row $width="18px" $height="20px" $align="center">
                <WarningIcon width="18" height="18" />
              </Row>
              <WarningMsg $size="var(--s-sub)" $font="var(--f-subB)" $line="var(--l-sub)" $padT="0">
                {t("infoMsg.betting_history")}
              </WarningMsg>
            </Row>
          </WarningBox>
          {getData?.length === 0 ? (
            <NoListLayout />
          ) : (
            <ScrollBox $pad={"20px"} $radius="12px" $backColor="var(--c-gray-700)">
              <Table className="table-box" $tLayout="fixed" $width="100%">
                <thead>
                  <tr className="table-title">
                    <TableHeader $minWidth={isScroll && "136px"} $tAlign="left" $width={isScroll && "136px"}>
                      {t("table.bet_number")}
                    </TableHeader>
                    <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>
                      {t("table.status")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.bet_money")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.win_money")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.vendor")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.game_name")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $tAlign="right" $width={isScroll && "136px"}>
                      {t("table.bet_date")}
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {getData &&
                    getData.map(
                      ({
                        user_history_no,
                        bet_amount,
                        win_amount,
                        game_title,
                        game_provider,
                        process_at,
                        transaction_type,
                      }) => {
                        let stateText = "";
                        let stateStyle = [];

                        switch (transaction_type) {
                          case "B":
                            stateText = t("state.betting");
                            stateStyle = ["subB"];
                            break;
                          case "W":
                            stateText = t("state.winning");
                            stateStyle = ["subB", "blue"];

                            break;
                          case "C":
                            stateText = t("state.cancel");
                            stateStyle = ["subB", "red"];
                            break;
                          default:
                            stateText = "";
                            stateStyle = [];
                        }
                        return (
                          <TableRow className="table-content" key={user_history_no}>
                            <TableDeps $white={"nowrap"} $tAlign="left" $overflow={"hidden"} $tOverflow={"ellipsis"} onClick={async () => {
                              copyText(user_history_no)
                            }} title={user_history_no}>
                              <DuplicationIcon />
                              {user_history_no}
                            </TableDeps>
                            <TableDeps $white={"wrap"}>
                              <TextBox $class={stateStyle}>{stateText}</TextBox>
                            </TableDeps>
                            <TableDeps $white={"wrap"}>
                              {bet_amount !== 0 ? bet_amount?.toLocaleString() : "-"}
                            </TableDeps>
                            <TableDeps $white={"wrap"} $color={win_amount && "var(--c-blue)"}>
                              {win_amount !== 0 ? win_amount?.toLocaleString() : "-"}
                            </TableDeps>
                            <TableDeps $white={"wrap"}>{game_provider}</TableDeps>
                            <TableDeps $white={"wrap"}>{game_title}</TableDeps>
                            <TableDeps $white={"wrap"} $tAlign="right">
                              {dateTimeFormat(process_at)}
                            </TableDeps>
                          </TableRow>
                        );
                      }
                    )}
                </tbody>
              </Table>
            </ScrollBox>
          )}
          {getData?.length !== 0 && <Pagination {...getBetting} />}
        </Column>
      </MyPageLayout>
      {toastOpen && <Toast Icon={<CheckIcon />} toastText={toastMsg} />}
    </FlexBox>
  );
};

export default MyBettingList;
