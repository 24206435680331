import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { getData } from "util/FormUtils";
import Calendar from "./Calendar";

import { Row } from "assets/Styles/custom/CustomLayout";
import { TextBox } from "assets/Styles/SText";
import { CalendarIcon } from "assets/components/IconComponent";
import { format } from "date-fns";
import { useMedia } from "util/useMedia";

const CalendarBox = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  gap: 6px;
  max-width: 158px;
  width: 158px;
  padding: 10px 16px;
  border-radius: 12px;
  background-color: var(--c-gray-700);

  @media (max-width: 700px) {
    width: 100%;
    max-width: 100%;
    position: initial;
  }
`;

const CalIconBox = styled.button`
  width: 20px;
  height: 20px;
`;

const CalendarFilter = ({ ...getValue }) => {
  const calendarRef = useRef(null);
  const { isMaxMiddleM } = useMedia();

  const [dateType, setDateType] = useState();

  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [calendarToggle, setCalenderToggle] = useState(false);

  const toggleOpen = (name) => {
    const sDate = getData(getValue.formValue, getValue.startName || "start_date", getValue.index, getValue.groupNm);
    const eDate = getData(getValue.formValue, getValue.endName || "end_date", getValue.index, getValue.groupNm);

    if (name === "start" && sDate) {
      setStartDate(sDate);
    } else if (name === "end" && eDate) {
      setEndDate(eDate);
    }

    setDateType(name);
    setCalenderToggle((prev) => (dateType === name ? !prev : true));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setCalenderToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getValue.onChange(getValue.startName || "start_date", startDate, getValue.index, getValue.groupNm);
    getValue.onChange(getValue.endName || "end_date", endDate, getValue.index, getValue.groupNm);
  }, [startDate, endDate]);
  return (
    <Row $gap="4px" $width={isMaxMiddleM && "100%"} $position={isMaxMiddleM && "relative"}>
      <Row $gap="4px" $width="100%" $align="center" ref={calendarRef}>
        <CalendarBox>
          <TextBox $class={["sub", "gray200"]}>
            {startDate ? format(new Date(startDate), "yyyy. MM. dd") : "YYYY.MM.DD"}
          </TextBox>

          {calendarToggle && dateType === "start" && (
            <Calendar
              endDate={endDate}
              dateType={dateType}
              startDate={startDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              onToggle={setCalenderToggle}
            />
          )}
          <CalIconBox onClick={() => toggleOpen("start")}>
            <CalendarIcon />
          </CalIconBox>
        </CalendarBox>
        <TextBox>~</TextBox>
        <CalendarBox>
          <TextBox $class={["sub", "gray200"]}>
            {endDate ? format(new Date(endDate), "yyyy. MM. dd") : "YYYY.MM.DD"}
          </TextBox>
          {calendarToggle && dateType === "end" && (
            <Calendar
              endDate={endDate}
              dateType={dateType}
              startDate={startDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              onToggle={setCalenderToggle}
            />
          )}

          <CalIconBox onClick={() => toggleOpen("end")}>
            <CalendarIcon />
          </CalIconBox>
        </CalendarBox>
      </Row>
    </Row>
  );
};

export default CalendarFilter;
