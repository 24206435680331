import { legacy_createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./reducers"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// 로컬 스토리지에서 초기 상태 불러오기
const loadState = () => {
  try {
      const serializedState = localStorage.getItem('info');
      if (serializedState === null) {
          return undefined;
      }
      return JSON.parse(serializedState);
  } catch (err) {
      return undefined;
  }
};

// 로컬 스토리지에 상태 저장하기
const saveState = (state) => {
  try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('info', serializedState);
  } catch (err) {
      console.error("Error saving info to local storage:", err);
  }
};

// 초기 상태를 로컬 스토리지에서 불러오기
const persistedState = loadState();

const store = legacy_createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

store.subscribe(() => {
  saveState(store.getState());
});

export default store
