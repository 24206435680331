import { Between, Center, ContentBox, Thumbnail, Grid } from "assets/Styles/custom/CustomLayout";
import { TextBox } from "assets/Styles/SText";
import { useCommonState } from "context/useContext";
import useGetData from "data/useGetData";
import { t } from "i18next";
import api from "interceptor/api";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";

const BannerPopBox = styled.div`
  width: 400px;
  height: 100%;
  position: absolute;
  top: ${({ $top }) => ($top ? `${$top}px` : "50%")};
  left: ${({ $left }) => ($left ? `${$left}px` : "50%")};
  transform: ${({ $top, $left }) => ($top && $left ? "none" : "translate(-50%, -50%)")};
  z-index: 10000;

  @media (max-width: 850px) {
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const BannerThumbnail = styled.div`
  width: 100%;
  height: 600px;
  background-color: var(--c-gray-900);
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);

  @media (max-width: 850px) {
    height: 450px;
  }
`;

const BannerContent = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  text-align: center;
  white-space: pre-wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--c-gray-900);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);

  @media (max-width: 850px) {
    height: 450px;
  }
`;

const Popup = () => {
  const [cookies, setCookie] = useCookies();
  const { isLogin } = useCommonState();
  const dispatch = useDispatch();

  const getPopup = () => {
    dispatch(loadingStatus(true));
    const apiUrl = "/v1/site/popup";
    api
      .get(apiUrl)
      .then((res) => {
        getPopupData.setData(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const getPopupData = useGetData({
    api: getPopup,
    firstInit: true,
  });

  const getData = getPopupData?.data;

  useEffect(() => {
    if (getData) {
      const newData = getData.data.map((item) => ({ ...item, view: item.no in cookies || item.view === 'N' ? 'N' : 'Y' }));
      JSON.stringify(newData) !== JSON.stringify(getData.data) && getPopupData.setData({ data: newData })
    }
  }, [getData, isLogin]);

  const CloseAllDay = (val) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    setCookie(val, true, { path: "/", expires: tomorrow });
    Close(val)
  };

  const Close = (val) => {
    const newData = getData.data.map((item) => ({ ...item, view: item.no === val || item.view === 'N' ? 'N' : 'Y' }))
    getPopupData.setData({ data: newData })
  };

  if (!getData) return null;
  return (
    <>
      <Grid>
        {getData.data?.length > 0 && (
          getData.data.map((data) => (
            data.view === 'Y' && <BannerPopBox
              $top={data.location_type === "F" && data.top}
              $left={data.location_type === "F" && data.left}
            >
              <ContentBox $shadow="0px 4px 20px 0px rgba(0, 0, 0, 0.3);" $backColor="var(--c-gray-600)" $radius="12px 12px 0 0">
                <Center $pad="16px 20px" $width="100%">
                  <TextBox $class={["title", "white"]}>
                    {data.title}
                  </TextBox>
                </Center>
              </ContentBox>
              <Link to={isLogin ? data.pop_link : '/'}>
                {data.contents && !data.img_uuid && (
                  <BannerContent>
                    <TextBox $class={["title", "white"]} $html={true} dangerouslySetInnerHTML={{ __html: data.contents }} />
                  </BannerContent>
                )}
                {data.img_uuid && (
                  <BannerThumbnail>
                    <Thumbnail
                      $width="100%"
                      $height={data.contents ? '' : "100%"}
                      $fit="contain"
                      src={process.env.REACT_APP_IMG_URL + data.img_uuid}
                    />
                    <TextBox $class={["title", "white"]} $html={true} dangerouslySetInnerHTML={{ __html: data.contents }} />
                  </BannerThumbnail>
                )}
              </Link>
              <ContentBox $shadow="0px 4px 20px 0px rgba(0, 0, 0, 0.3);" $backColor="var(--c-gray-600)" $radius="0 0 12px 12px">
                <Between $pad="16px 20px" $width="100%">
                  <TextBox as="button" $cursor="pointer" onClick={() => CloseAllDay(data.no)} $class={["title", "white"]}>
                    {t("button.hide_today")}
                  </TextBox>
                  <TextBox as="button" $cursor="pointer" onClick={() => Close(data.no)} $class={["title", "white"]}>
                    {t("button.close")}
                  </TextBox>
                </Between>
              </ContentBox>
            </BannerPopBox>
          ))
        )}
      </Grid>
    </>
  );
};

export default Popup;
