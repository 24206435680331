import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const createRedBtn = ({ $pad, $width, $size, $font, $line }) => css`
  width: ${$width};
  min-width: 40px;

  min-height: 36px;
  padding: ${$pad || "0 12px"};

  white-space: nowrap;
  color: var(--c-red);
  background-color: var(--c-white);

  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};

  border: 1px solid var(--c-gray-300);
`;

const createActionBtn = ({ $pad, $mar, $size, $font, $line, $width, $tAlign, $radius, $backColor, $hoverBackColor, $fontWeight }) => css`
  color: var(--c-white);
  background: ${$backColor || "var(--c-gray-600)"};
  width: ${$width};
  margin: ${$mar};

  padding: ${$pad || "0 12px"};

  width: ${$width};
  white-space: nowrap;
  text-align: ${$tAlign};
  &:hover {
    background-color : ${$hoverBackColor}
  }
  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};
  border-radius: ${$radius};
  font-weight: ${$fontWeight};
`;

const createTabBtn = ({
  $gap,
  $pad,
  $size,
  $font,
  $line,
  $color,
  $white,
  $width,
  $height,
  $radius,
  $position,
  $top,
  $left,
  $right,
  $minWidth,
  $minHeight,
  $backColor,
}) => css`
  width: ${$width};
  min-width: ${$minWidth};

  padding: ${$pad};
  position: ${$position};
  top: ${$top};
  left: ${$left};
  right: ${$right};

  height: ${$height};
  min-height: ${$minHeight};
  white-space: ${$white};
  color: ${$color};
  border-radius: ${$radius};
  background-color: ${$backColor};

  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${$gap};

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    & > svg path {
      fill: var(--c-white);
    }
    & > span,
    p {
      color: var(--c-white);
    }
  }
`;

const createPGradient = ({
  $mar,
  $pad,
  $size,
  $font,
  $line,
  $over,
  $color,
  $width,
  $height,
  $tAlign,
  $radius,
  $minWidth,
  $maxWidth,
  $minHeight,
}) => css`
  width: ${$width ? $width : "100%"};

  padding: ${$pad};

  height: ${$height};
  min-height: ${$minHeight};
  text-align: ${$tAlign};
  max-width: ${$maxWidth};
  min-width: ${$minWidth || $width};

  white-space: nowrap;
  background: var(--c-pGradient);
  border-radius: ${$radius};

  font-size: ${$size};
  font-family: ${$font ? $font : "var(--f-subB)"};
  color: ${$color ? $color : "var(--c-white)"};
  line-height: ${$line};
  margin: ${$mar};

  overflow: ${$over};

  &:disabled {
    opacity: 0.4;
  }
`;

const RedBtn = styled.button`
  ${createRedBtn}
`;

const ActionBtn = styled.button`
  ${createActionBtn}
`;

const TabBtn = styled.button`
  ${createTabBtn}
`;

const P_GradientBtn = styled.button`
  ${createPGradient}
`;

const P_GradientBox = styled.div`
  ${createPGradient}
`;

const CategoryMenu = styled.button`
  min-width: 120px;
  padding: 12px 0;
  text-align: center;
  white-space: nowrap;
  color: var(--c-gray-700);
  background-color: rgba(95, 158, 160, 0.2);

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  &:hover {
    color: var(--c-white);
    background-color: var(--c-mint-600);
  }
  transition: 0.2s ease-in-out;
  &.active {
    color: var(--c-white);
    background-color: var(--c-mint-600);
  }
  &:first-of-type {
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
  }
`;

const CheckBtn = styled.button`
  width: 80px;
  padding: 6px 0;
  font-size: var(--s-sub);
  line-height: var(--l-sub);

  border: 1px solid var(--c-gray-300);
`;

const ToggleBtn = styled.button`
  border: 1px solid var(--c-gray-300);
  background-color: var(--c-white);

  width: ${(props) => props.$width || "60px"};
  height: 34px;
  text-align: center;
  white-space: nowrap;

  &.active {
    border: none;
    color: var(--c-white);
    background-color: var(--c-mint-600);
  }
`;

const OauthSign = styled.button`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 12px 16px;
  border-radius: 12px;
  color: var(--c-white);
  font-family: var(--f-subB);
  background-color: var(--c-gray-700);
`;

const ContentSwap = styled.a`
  width: 100%;
  padding: 10px 0;
  text-align: center;

  border-radius: 30px;
  background-color: var(--c-black);

  color: var(--c-gray-300);
  font-size: var(--s-title);
  font-family: var(--f-title);
  line-height: var(--l-title);

  &.active {
    color: var(--c-white);
    background-color: var(--c-gray-700);
  }

  @media (max-width: 700px) {
    font-size: var(--s-sub);
    font-family: var(--f-subB);
    line-height: var(--l-sub);
  }
`;

const CashPlusBtn = styled.button`
  width: 100%;
  padding: 6px 0;
  text-align: center;
  color: var(--c-white);

  border-radius: 12px;
  border: 1px solid var(--c-gray-600);
  background-color: var(--c-gray-700);
`;

const ResetBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 32px;
  min-height: 32px;

  border-radius: 12px;
  background: var(--c-gray-700);
  border: 1px solid var(--c-gray-600);
`;

const BorderBtn = styled.button`
  padding: ${(props) => props.$pad ?? "6px 10px"};
  height: fit-content;
  display: ${(props) => props.$dis};
  background: ${(props) => props.$backColor};
  border-radius: ${(props) => props.$radius ?? "12px"};
  border: ${(props) => props.$bor ?? "1px solid var(--c-gray-500)"};
`;

export {
  RedBtn,
  ActionBtn,
  TabBtn,
  ToggleBtn,
  P_GradientBtn,
  ResetBtn,
  CheckBtn,
  CategoryMenu,
  OauthSign,
  ContentSwap,
  CashPlusBtn,
  BorderBtn,
  P_GradientBox,
};
