import React from "react";
import SwiperComponent from "components/hooks/SwiperComponent";

import { GameInfoBoxTab, SectionBox, Slide } from "assets/Styles/SLayout";
import { Between, Column, ContentBox, Row, Thumbnail } from "assets/Styles/custom/CustomLayout";
import { SectionTitle } from "components/common/useCommonStyle";
import { SlideForm } from "data/useForm";
import { TextBox } from "assets/Styles/SText";
import { useDispatch } from "react-redux";
import { loadingStatus } from "../../store/actions";

import { useMedia } from "util/useMedia";
import { CutWord } from "assets/Styles/custom/CustomText";
import { useCommonState } from "context/useContext";
import { useStateCheck } from "util/useStateCheck";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import NoListLayout from "components/common/NoListLayout";
import { P_GradientBtn } from "assets/Styles/SButton";
import { t } from "i18next";
import styled from "styled-components";
import { LinkIcon } from "assets/components/IconComponent";
import { useAlert } from "context/useWindow";

const DivStyleBox = styled.div``

const PopularGame = () => {
  const dispatch = useDispatch();
  const stateCheck = useStateCheck();
  const { isLogin } = useCommonState();
  const { openAlert } = useAlert();

  const { isDesktop, isMiddleD, isTablet, isMaxTablet, isMaxMiddleM, isMiddleM } = useMedia();

  const getWidth = () => {
    if (isDesktop) return "179px";
    if (isMiddleD) return "164px";
    if (isTablet) return "155px";
    if (isMaxMiddleM) return "115px";
    return "155px";
  };

  const size = getWidth();

  const getGameList = () => {
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/game/popular-game";
    api
      .get(apiUrl, {
        params: {
          ...useGame.extendData.pagingData,
        },
      })
      .then((res) => {
        useGame.setData(res.data.content);
        useGame.setError("");
      })
      .catch((err) => {
        console.error(err);
        useGame.setData([]);
        useGame.setError(true);
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
  };

  const errorAlert = () => {
    openAlert({
      title: t("alert.game_error_title"),
      message: t("alert.game_error"),
    });
  };

  const ApiCallback = (vendor, id) => {
    dispatch(loadingStatus(true))

    const apiUrl = "/v1/user-game/game-launch-link";
    api
      .get(apiUrl, {
        params: {
          game_id: id,
          vendor: vendor,
        },
      })
      .then((res) => {
        window.open(res.data.content.link, '_blank');
      })
      .catch((error) => {
        console.error(error);
        errorAlert();
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
  }

  const useGame = useGetData({
    pageSize: "20",
    firstInit: true,
    api: getGameList,
  });

  const slideData = () => {
    return (
      <>
        {useGame &&
          useGame?.data?.map(({ title, id, thumbnail, vendor, is_inner_game, url }) => (
            <Slide key={id + vendor}>
              <ContentBox
                $width={size}
                $direct="column"
                $align="center"
                $cursor="pointer"
                $backColor="var(--c-gray-700)"
                $radius={isMaxMiddleM ? "8px" : "12px"}
                onClick={() => {
                  is_inner_game == 'Y' ? stateCheck(`/${url}`) : stateCheck(`/game_zone/${vendor}/${id}`)
                }}
              >
                <Thumbnail
                  src={thumbnail}
                  $width={size}
                  $height={size}
                  $radius={isMaxMiddleM ? "8px 8px 0 0" : "12px 12px 0 0"}
                  $backColor="var(--c-gray-600)"
                />
                <GameInfoBoxTab $pad={isMaxTablet && "8px 12px"}>
                  <Column $width="100%">
                    <Row>
                      <CutWord $maxWidth="100%">{title}</CutWord>
                      <DivStyleBox onClick={(e) => {
                        if (isLogin) {
                          e.stopPropagation();
                          ApiCallback(vendor, id)
                        }
                      }}
                      >
                        <LinkIcon
                          width="24"
                          height="24"
                        />
                      </DivStyleBox>
                    </Row>
                    {isTablet && (
                      <P_GradientBtn $mar="10px 0 0" $pad="12px 0" $radius="999px" $align="center">
                        {t("button.now_join_game")}
                      </P_GradientBtn>
                    )}
                  </Column>
                </GameInfoBoxTab>
              </ContentBox>
            </Slide>
          ))}
      </>
    );
  };

  return (
    <SectionBox>
      <Between>
        <SectionTitle name={t("sectionTitle.popular_games")} />
        {useGame?.data?.length !== 0 && (
          <Row $align="center" $gap="16px">
            <TextBox as="a" $cursor="pointer" onClick={() => stateCheck("/popular_games")} $class="purple">
              {t("button.view_all")}
            </TextBox>
            {isMiddleM && (
              <SlideForm
                width="36px"
                radius="12px"
                height="36px"
                backColor="var(--c-gray-700)"
                prevEl="popular-prev-button"
                nextEl="popular-next-button"
              />
            )}
          </Row>
        )}
      </Between>
      {useGame?.data?.length > 0 ? (
        <SwiperComponent
          spaceBetween="8"
          slideData={slideData}
          useNavigation={true}
          slidesPerView={"auto"}
          prevEl=".popular-prev-button"
          nextEl=".popular-next-button"
        />
      ) : (
        <NoListLayout pad="65px 0" />
      )}
    </SectionBox>
  );
};

export default PopularGame;
