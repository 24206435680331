import { postRequest } from "apis/postRequest";

const useLogout = () => {
  const logout = async () => {
    await postRequest({
      url: "/v1/user/logout",
      body: {},
    })
    setTimeout(() => {
      localStorage.clear();
      window.location.reload();
      window.location.href = "/";
    }, 500);
  };

  return {
    logout,
  };
};

export default useLogout;
