const unitFormat = (value) => {
  if (value >= 1000000) {
    return Math.floor(value / 100000) / 10 + "M"; // 예: 1,000,000 이상일 경우 M으로 표기
  } else if (value >= 1000) {
    return Math.floor(value / 100) / 10 + "K"; // 예: 1,000 이상일 경우 K로 표기
  } else {
    return value?.toString(); // 1,000 미만은 그대로 표기
  }
};

export { unitFormat };
