import api from "interceptor/api";

export const deleteRequest = ({ url, params, errorState, errorResponse, Func }) => {
  if (errorState && errorState()) {
    return;
  }
  api
    .delete(url, { params })
    .then((res) => {
      if (Func) {
        Func();
      }
    })
    .catch((err) => {
      if (err.response.data.errors) {
        const errorCodes = err.response.data.errors[0]?.msg?.code;
        if (errorCodes) {
          errorResponse(errorCodes);
        }
      }
      console.error(err);
    });
};
