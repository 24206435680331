import React from "react";
import styled from "styled-components";

import { P_GradientBtn } from "assets/Styles/SButton";
import { LineSection, PadBox } from "assets/Styles/SLayout";
import { A, TextBox } from "assets/Styles/SText";
import { Column, Row } from "assets/Styles/custom/CustomLayout";
import { useMedia } from "util/useMedia";
import { t } from "i18next";
import { Link } from 'react-router-dom';
import OauthLogin from "./OauthLogin";

const GuideBox = styled.div`
  text-align: center;
`;

const Sign = () => {
  const { isMaxMiddleM } = useMedia();
  return (
    <Column $gap={isMaxMiddleM ? "16px" : "28px"} $align="center">
      <Row>
        <TextBox $class={[isMaxMiddleM ? "title" : "header", "white"]}>{t("common.sign_up")}</TextBox>
      </Row>

      <LineSection $width="100%" $borB="1px solid var(--c-gray-600)" />

      <Column $gap="12px" $align="center" $width="100%" $maxWidth="328px">
        <P_GradientBtn $tAlign="center" $width="100%" $pad="12px 0" $radius="12px" as={Link} to="/sign_up">
          {t("button.sign_up_with_id")}
        </P_GradientBtn>

        <TextBox $class={["caption", "gray300"]}>{t("common.or")}</TextBox>

        <Row $gap="8px">
          <OauthLogin />
        </Row>
      </Column>

      <PadBox $pad="12px 0">
        <GuideBox>
          <TextBox as="p" $dis="block" $class={["caption", "gray300"]}>
            {t("infoMsg.create_account")} &nbsp;
            <A as={Link} to="/sign_up" $class={["caption", "purple"]} $textDeco="underline">
              {t("infoMsg.service_agreement")}
            </A>
            {t("infoMsg.agree_service_agreement")}
          </TextBox>
          <TextBox as="p" $dis="block" $class={["caption", "gray300"]}>
            {t("infoMsg.privacy_policy_detail")}
          </TextBox>
          <TextBox as="p" $dis="block" $class={["caption", "gray300"]}>
            <A as={Link} to="/sign_up" $class={["caption", "purple"]} $textDeco="underline">
              {t("infoMsg.privacy_policy")}
            </A>
            {t("infoMsg.privacy_policy_ref")}
          </TextBox>
        </GuideBox>
      </PadBox>
    </Column>
  );
};

export default Sign;
