import React, { createContext, useContext, useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";
import { jwtDecode } from "jwt-decode";
import api from "interceptor/api";

const CommonContext = createContext();

const checkIsLogin = () => {
  const info = localStorage.getItem("info");
  if (info) { 
    const { Client } = JSON.parse(info)
    return Client.token.accessToken && Client.token.refreshToken
  }
  return false
};

export const CommonProvider = ({ children }) => {
  const [error, setError] = useState();
  const [userType, setUserType] = useState();

  const [signUp, setSignUp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [singupWaitLimit, setSignupWaitLimit] = useState(false);

  const [userState, setUserState] = useState(""); //2024.10.16 Redux 사용이후 사용 X??? 
  const [isRefer, setIsRefer] = useState(0);

  const [inputError, setInputError] = useState({});
  const [eventSource, setEventSource] = useState({});

  const [isLogin, setIsLogin] = useState(checkIsLogin);
  const [readNote, setReadNote] = useState({});
  const dispatch = useDispatch();

  let accessToken = null
  let decoded = null
  let userNo = null
  const info = localStorage.getItem("info");
  if (info) { 
    const { Client } = JSON.parse(info)
    accessToken = Client.token.accessToken
    decoded = accessToken ? jwtDecode(accessToken) : null;
    userNo = decoded ? decoded.no : null;
  }

  const getUser = async () => {
    setLoading(true);
    dispatch(loadingStatus(true));
    try {
      const res = await api.get("/v1/user");
      setUserState(res.data.content.state1);
      setIsRefer(res.data.content.isRefer);
      setSignUp(res.data.content.is_exist_user_data);
      setUserType(res.data.content.type);
      setSignupWaitLimit(res.data.content.signup_wait_limit)
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      dispatch(loadingStatus(false));
    }
  };

  // useEffect(() => {
  //   if (isLogin) {
  //     getUser();
  //   }
  // }, [isLogin]);
  
  const contextValue = {
    getUser, 

    loading,
    setLoading,

    isLogin,
    setIsLogin,

    inputError,
    setInputError,

    error,
    setError,

    isRefer,
    setIsRefer,

    singupWaitLimit,
    setSignupWaitLimit,

    eventSource,
    setEventSource,

    signUp,
    userState,

    readNote,
    setReadNote,

    userNo,
    decoded,
    userType,
    accessToken,
  };

  return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
};

export const useCommonState = () => {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error("useCommonContext must be used within a CommonProvider");
  }
  return context;
};
