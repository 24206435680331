import styled from "styled-components";
import { Column } from "../custom/CustomLayout";

const MainLayout = styled.main`
  min-height: 100vh;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  margin-left: 266px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.slide {
    margin-left: 97px;

    .menu-arrow,
    .menu-group {
      display: none;
    }
  }

  @media (max-width: 1280px) {
    margin-left: 97px;
  }

  @media (max-width: 850px) {
    padding-right: 20px;
  }

  @media (max-width: 700px) {
    padding: 0;
    margin-left: 0;
  }
`;

const ContentWrap = styled(Column)`
  width: 100%;
  flex: 1;
`;

const Article = styled.article`
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
  max-width: 1574px;

  padding: 28px;
  border-radius: 24px;
  background: var(--c-gray-800);

  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    margin-top: 0px;
    border-radius: 0px;
    padding: 16px;
  }
`;

export { ContentWrap, MainLayout, Article };
