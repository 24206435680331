import React from "react";
import styled from "styled-components";

import { Empty } from "assets/Styles/SLayout";
import { TextBox, WarningMsg } from "assets/Styles/SText";
import { WarningIcon } from "assets/components/IconComponent";
import { Column, ContentBox } from "assets/Styles/custom/CustomLayout";

import { timeFormat } from "util/TimeFormat";

const InspectionLayout = styled.div`
  width: 100vw;
  height: 100vh;
  background: var(--c-gray-800);
  padding-top: 80px;

  @media (max-width: 700px) {
    padding: 80px 20px;
  }
`;

const InspectionContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
`;

const Inspection = ({ inspection }) => {
  return (
    <InspectionLayout>
      <InspectionContent>
        <Column $gap="6px" $align="center">
          <WarningIcon width="36" height="36" />
          <WarningMsg $padT="0" $size="var(--s-inspection)" $font="var(--f-inspection)" $line="var(--l-inspection)">
            {inspection.inspec_yn === "Y" ? "긴급점검 중 입니다" : "점검 중 입니다"}
          </WarningMsg>
          {inspection.plan_inspec_yn === "Y" && inspection.inspec_yn === "N" && (
            <>
              <TextBox $class={["header", "white"]}>점검일자: {inspection.plan_inspec_week}</TextBox>
              <TextBox $class={["header", "white"]}>
                {timeFormat(inspection.plan_inspec_start)} ~ {timeFormat(inspection.plan_inspec_end)}
              </TextBox>
            </>
          )}
        </Column>
        <ContentBox $jus="center" $width="100%" $radius="12px" $backColor="var(--c-gray-700)">
          {inspection.inspec_yn === "Y" ? (
            <TextBox $pad="20px 24px" $tAlign="center" $class={["title"]}>
              {inspection.inspec_memo}
            </TextBox>
          ) : (
            <Empty $pad="20px 24px">
              <TextBox as="p" $dis="block" $tAlign="center" $class={["title"]}>
                서비스 이용에 불편을 드려 죄송합니다.
              </TextBox>
              <TextBox as="p" $dis="block" $tAlign="center" $class={["title"]}>
                조속히 정상적인 서비스를 제공해 드릴 수 있도록 최선을 다하겠습니다.
              </TextBox>
            </Empty>
          )}
        </ContentBox>
      </InspectionContent>
    </InspectionLayout>
  );
};

export default Inspection;
