import React from "react";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";

import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";

import { TextBox } from "assets/Styles/SText";
import { PrevIcon } from "assets/components/IconComponent";
import { Column, RowLink, Thumbnail } from "assets/Styles/custom/CustomLayout";
import { GrayCon, FlexBox, MyPageLayout, LineSection, Empty, PadBox } from "assets/Styles/SLayout";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import { dateTimeFormat } from "util/DateUtil";
import { t } from "i18next";
import { SectionTitle } from "components/common/useCommonStyle";

const NoticeDetail = () => {
  const { no } = useParams();
  const { setLoading } = useCommonState();
  const { isMiddleD, isMaxMiddleM } = useMedia();
  const dispatch = useDispatch();

  const getNoticeInfo = () => {
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = `/v1/user/notice/${no}`;

    api
      .get(apiUrl)
      .then((res) => {
        setLoading(false);
        getNotice.setError("");
        getNotice.setData(res.data.content);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        getNotice.setData([]);
        getNotice.setError(true);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const getNotice = useGetData({
    firstInit: true,
    api: getNoticeInfo,
  });

  const getData = getNotice.data;
  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <SectionTitle name={t("sectionTitle.notice")} />
      <MyPageLayout>
        <Column $gap="24px">
          <RowLink to="/notice" $gap="4px">
            <PrevIcon width="16" height="16" fill="var(--c-gray-300)" />
            <TextBox $class="gray300">{t("button.return_list")}</TextBox>
          </RowLink>

          <GrayCon $radius="12px" $pad={isMaxMiddleM ? "20px" : "24px 28px"}>
            <Column $gap="20px" $align="left">
              <Empty>
                <TextBox $class={[isMaxMiddleM ? "title" : "header", "white"]}>{getData.title}</TextBox>
                {getData.create_at && (
                  <PadBox $pad="8px 0 0">
                    <TextBox $class="gray300">{dateTimeFormat(getData.create_at)}</TextBox>
                  </PadBox>
                )}
              </Empty>

              <LineSection $width="100%" $borB="1px solid var(--c-gray-500)" />
              <TextBox $class="white">{getData.contents}</TextBox>

              {getData.uuid && (
                <Thumbnail $radius="12px" src={process.env.REACT_APP_IMG_URL + getData.uuid} alt={getData.name} />
              )}
            </Column>
          </GrayCon>
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default NoticeDetail;
