import React from "react";
import { Swiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css/bundle";

const SwiperComponent = ({
  slideData,
  spaceBetween,
  slidesPerView = "auto",
  useAutoplay = false,
  usePagination = false,
  useNavigation = false,
  loop = false,
  swiperClass = "swiper",
  delay = 3000,
  prevEl = ".swiper-btn-prev",
  nextEl = ".swiper-btn-next",
  slidesPerGroup = 1,
  initialSlide = 0,
  centeredSlidesBounds = false,
  rewind = false,
  loopAdditionalSldies,
}) => {
  const modules = [useAutoplay && Autoplay, useNavigation && Navigation, usePagination && Pagination].filter(Boolean);
  // console.log(loopAdditionalSldies);
  const swiperProps = {
    className: swiperClass,
    modules,
    spaceBetween,
    slidesPerView,
    slidesPerGroup,
    initialSlide,
    loop,
    centeredSlidesBounds,
    loopAdditionalSldies,
    ...(useNavigation && { navigation: { prevEl, nextEl } }),
    ...(useAutoplay && {
      autoplay: {
        delay: delay,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        stopOnLastSlide: false,
      },
    }),
    ...(usePagination && {
      pagination: {
        clickable: true,
      },
    }),
    ...(rewind && { rewind: true }),
  };

  return <Swiper {...swiperProps}>{slideData()}</Swiper>;
};

export default SwiperComponent;
