import React, { useEffect, useState } from "react";

import { TextBox } from "assets/Styles/SText";
import { InputCon } from "assets/Styles/SInput";
import { P_GradientBtn } from "assets/Styles/SButton";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";

import { patchRequest } from "apis/patchRequest";
import { Timer } from "components/sign/Timer";
import { useAlert } from "context/useWindow";
import { getErrorClass, validateField } from "util/FormStateUtil";
import { useCommonState } from "context/useContext";
import { InputForm, SelectForm } from "data/useForm";

import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import { t } from "i18next";
import api from "interceptor/api";
import { useDispatch } from "react-redux";
import { loadingStatus } from "../../store/actions";

const options = [
  { label: "+82", value: "+82", key: 1 },
  { label: "+92", value: "+92", key: 2 }
];

const PhonePopup = ({ openToast, setToastMsg, closePopup, getProfileInfo }) => {
  const phoneRegex = /^(01[0-9]|10[0-9])(\d{3,4})(\d{4})$/;
  const { openAlert } = useAlert();

  const { inputError, setInputError } = useCommonState();

  const [certifyNum, setCertifyNum] = useState(false);
  const [timerActive, setTimerActive] = useState(false);

  const dispatch = useDispatch();

  const getValue = useFormData({
    phone_number: "",
    certify: "",
    country_code: "",
  });

  const certifyReset = () => {
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      certify: "",
    }));
    setCertifyNum(false);
  };

  const alert = () => {
    openAlert({
      message: t("alert.auth_num_expired"),
      message2: t("alert.retry"),
      Func: () => certifyReset(),
    });
  };

  const errorState = () => {
    let hasError = false;
    if (!getValue.formValue.phone_number.length) {
      setInputError((prev) => ({
        ...prev,
        phone_number: t("error.not_match_password"),
      }));
      hasError = true;
    }

    return hasError;
  };

  const errorResponse = (errorCodes) => {
    let errorMessage = "";
    switch (errorCodes) {
      case "M001":
        errorMessage = t("error.check_authCode");
        break;
      case "M002":
        errorMessage = t("error.same_phone_number");
        break;
      case "M003":
        errorMessage = t("error.phone_number_already");
        break;
      default:
        errorMessage = t("error.unknown"); 
    }

    setInputError((prev) => ({
      ...prev,
      certify: errorMessage,
    }));
  };

  const func = () => {
    closePopup();
    openToast();
    setToastMsg(t("toast.action_change", { action: t("common.phone_num") }));
    getProfileInfo();
  };

  const patchAction = () => {
    patchRequest({
      url: "/v1/user/phone-number",
      params: {
        ...getValue.formValue,
      },
      errorState: errorState,
      errorResponse: errorResponse,
      Func: func,
    });
  };

  const certifyShow = () => {
    dispatch(loadingStatus(true));
    const apiUrl = "/v1/auth/not-exist-phoneNo"
    api
      .post(apiUrl, {
        ...getValue.formValue,
      })
      .then((res) => {
        const { success } = res.data.content;
        if (success) {
          setCertifyNum(true);
          setTimerActive(true);
        } else {
          openAlert({
            message: t("common.notice"),
            message2: t("alert.notExist"),
          });
        }
      })
      .catch((err) => {
        if (!err?.response || err?.response.status == 500) {
          openAlert({
            message: t("common.notice"),
            message2: t("error.unknown"),
          });
        } else if (err?.response.data.content.code == 'M001') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.blocked_member"),
          });
        } else if (err?.response.data.content.code == 'M002') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.leave_member"),
          });
        } else if (err?.response.data.content.code == 'M003') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.phone_number_already"),
          });
        } else if (err?.response.data.content.code == 'M004') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.not_auth_email"),
          });
        } else if (err?.response.data.content.code == 'M005') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.sms_failed"),
          });
        }

        setCertifyNum(false)

        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const blurValid = (name, errorMsg) => {
    const currentError = { ...inputError };
    const formValue = formValued;

    let getError = { ...currentError };

    validateField(formValue, name, errorMsg, getError);

    const validationMap = {
      phone_number: () => {
        if (formValue.phone_number.length === 0) {
          getError.phone_number = t("error.proceed_certification");
        } else if (!phoneRegex.test(formValue.phone_number)) {
          getError.phone_number = t("error.not_correct_cell_phone_number");
        }
      },
    };

    validationMap[name]?.();
    setInputError(getError);
  };

  const formValued = getValue.formValue;

  useEffect(() => {
    setTimerActive(false)
    setCertifyNum(false);
  }, [getValue.formValue.phone_number])

  useEffect(() => {
    setTimerActive(false)
    setCertifyNum(false);
  }, [getValue.formValue.country_code])

  // TODO : 비밀번호 에러 걸 수 없음 추후에 나오면 걸어야함.
  return (
    <>
      <Column $gap="8px" $width="100%" $pad="0px 0 20px">
        <SelectForm
          name="country_code"
          selectType="search"
          placeholder={t("placeholder.country_num")}
          conWidth="100%"
          options={options}
          errors={inputError}
          setErrors={setInputError}
          errorMsg={t("error.country_num_required")}
          pad="12px 16px"
          minWidth="100%"
          size="var(--s-sub)"
          height="44px"
          selectColor="var(--c-white)"
          errorPadT="0px"
          {...getValue}
        />
        <Row $gap="8px">
          <InputCon className={getErrorClass(inputError, "phone_number")} $pad="12px 16px" $radius="12px">
            <InputForm
              name="phone_number"
              placeholder={t("placeholder.no_hyphen_num")}
              errors={inputError}
              setErrors={setInputError}
              onBlur={() => blurValid("phone_number", t("error.phone_num_required"))}
              backColor="var(--c-gray-700)"
              {...getValue}
            />
          </InputCon>
          <P_GradientBtn
            onClick={certifyShow}
            $radius="12px"
            $width="71px"
            $pad="12px 0"
            disabled={(!phoneRegex.test(formValued.phone_number) || !formValued.country_code)}
          >
            {t("button.certify")}
          </P_GradientBtn>
        </Row>
        <Error padT="0px" errors={inputError} name={"phone_number"}></Error>
        <>
          <Row $gap="8px">
            <InputCon className={getErrorClass(inputError, "certify")} $pad="12px 16px" $radius="12px">
              <Between>
                <InputForm
                  name="certify"
                  placeholder={t("placeholder.auth_num")}
                  errors={inputError}
                  setErrors={setInputError}
                  {...getValue}
                  backColor="var(--c-gray-700)"
                />
                {certifyNum && <Timer alert={alert} reTimer={timerActive} setReTimer={setTimerActive} />}
              </Between>
            </InputCon>
          </Row>
          {inputError ? (
            <Error padT="0px" errors={inputError} name={"certify"}></Error>
          ) : (
            ""
          )}
        </>
      </Column >
      <P_GradientBtn $pad="12px 0" $radius="12px" onClick={patchAction} disabled={formValued.certify.length !== 4 || timerActive


      }>
        {t("button.change")}
      </P_GradientBtn>
    </>
  );
};

export default PhonePopup;
