import React, { useState } from "react";

import { InputCon } from "assets/Styles/SInput";
import { P_GradientBtn } from "assets/Styles/SButton";
import { PasswordIcon } from "assets/components/IconComponent";
import { Between, Column } from "assets/Styles/custom/CustomLayout";

import { InputForm } from "data/useForm";
import { patchRequest } from "apis/patchRequest";
import { useCommonState } from "context/useContext";
import { getErrorClass, validateField } from "util/FormStateUtil";

import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import { t } from "i18next";

const WithdrawPWPopup = ({ openToast, setToastMsg, closePopup }) => {
  const { inputError, setInputError } = useCommonState();

  const [passwordState, setPasswordState] = useState({
    account_out_password: { hide: false, type: "password" },
    new_account_out_password: { hide: false, type: "password" },
  });

  const HidePassword = (type) => {
    setPasswordState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        hide: !prevState[type].hide,
        type: prevState[type].hide ? "password" : "text",
      },
    }));
  };

  const getValue = useFormData({
    account_out_password: "",
    new_account_out_password: "",
  });

  const formValue = getValue.formValue;

  const errorResponse = (errorCodes) => {
    let errorMessage = "";

    switch (errorCodes) {
      case "M006":
        errorMessage = t("error.wrong_withdraw_password");
        break;

      default:
        errorMessage = t("error.unknown");
    }

    setInputError((prev) => ({
      ...prev,
      account_out_password: errorMessage,
    }));
  };

  const patchAction = () => {
    patchRequest({
      url: "/v1/user/out-password",
      params: {
        ...getValue.formValue,
      },
      errorResponse: errorResponse,
      Func: func,
    });
  };

  const func = () => {
    closePopup();
    openToast();
    setToastMsg(t("toast.action_change", { action: t("common.withdraw_password") }));
  };

  const blurValid = (name, errorMsg) => {
    const currentError = { ...inputError };

    let getError = { ...currentError };

    validateField(formValue, name, errorMsg, getError);
    switch (name) {
      case "account_out_password":
        if (formValue.account_out_password.length !== 6) {
          getError.account_out_password = errorMsg;
        }
        break;
      case "new_account_out_password":
        if (formValue.new_account_out_password.length !== 6) {
          getError.new_account_out_password = errorMsg;
        }
        break;

      default:
        break;
    }
    setInputError(getError);
  };

  return (
    <>
      <Column $gap="8px" $width="100%" $pad="0 0 20px">
        <InputCon className={getErrorClass(inputError, "account_out_password")} $radius="12px" $pad="12px 16px">
          <Between $gap="12px">
            <InputForm
              height={"20px"}
              type={passwordState.account_out_password.type}
              name="account_out_password"
              backColor={"var(--c-gray-700)"}
              placeholder={t("placeholder.current_withdraw_password")}
              onBlur={() => blurValid("account_out_password", t("error.correct_withdraw_password"))}
              errors={inputError}
              setErrors={setInputError}
              {...getValue}
            />
            <PasswordIcon
              onClick={() => HidePassword("account_out_password")}
              show={passwordState.account_out_password.hide}
              width="20"
              height="20"
              fill="var(--c-gray-300)"
            />
          </Between>
        </InputCon>
        <Error padT="0px" errors={inputError} name={"account_out_password"}></Error>
        <InputCon className={getErrorClass(inputError, "new_account_out_password")} $radius="12px" $pad="12px 16px">
          <Between $gap="12px">
            <InputForm
              height={"20px"}
              type={passwordState.new_account_out_password.type}
              name="new_account_out_password"
              backColor={"var(--c-gray-700)"}
              placeholder={t("placeholder.new_withdraw_password")}
              onBlur={() => blurValid("new_account_out_password", t("error.new_withdraw_password"))}
              errors={inputError}
              setErrors={setInputError}
              {...getValue}
            />
            <PasswordIcon
              onClick={() => HidePassword("new_account_out_password")}
              show={passwordState.new_account_out_password.hide}
              width="20"
              height="20"
              fill="var(--c-gray-300)"
            />
          </Between>
        </InputCon>
        <Error padT="0px" errors={inputError} name={"new_account_out_password"}></Error>
      </Column>
      <P_GradientBtn
        $pad="12px 0"
        $radius="12px"
        onClick={patchAction}
        disabled={formValue.account_out_password.length !== 6 || formValue.new_account_out_password.length !== 6}
      >
        {t("button.change")}
      </P_GradientBtn>
    </>
  );
};

export default WithdrawPWPopup;
