import { ColorRing } from 'react-loader-spinner';

/**
 * 스피너
 * @param {boolean} show 보여짐 여부
 */
const Spinner = ({ show }) => {
  return (
    <div className={`${show ? 'spiner-loading' : null}`}>
      <ColorRing
        visible={show}
        height="80"
        width="80"
        colors={['#7E51FF', '#561BFF', '#7E51FF', '#561BFF', '#7E51FF']}
      />
    </div>
  )
}

export { Spinner }