import React from "react";
import { t } from "i18next";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { TextBox } from "assets/Styles/SText";
import { Column } from "assets/Styles/custom/CustomLayout";
import { MenuArrow } from "assets/components/IconComponent";
import { SectionTitle } from "components/common/useCommonStyle";
import { useCommonState } from "context/useContext";

import { useMedia } from "util/useMedia";
import { useStateCheck } from "util/useStateCheck";
import { isActiveMenuItem, useMenu } from "constant/useMenu";

import useOutsideBlur from "components/hooks/useOutSideBlur";
import { useConfirm } from "context/useWindow";

const DropWrapper = styled.div`
  width: 158px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 16px;

  border-radius: 12px;
  background-color: var(--c-gray-700);
`;

const MyDropdownCon = styled.div`
  z-index: 99;

  width: 158px;
  border-radius: 20px;
  background-color: var(--c-gray-700);
`;

const MyDropdownUl = styled.ul`
  padding: 8px;
`;

const MyDropdownLi = styled.li`
  padding: 10px 16px;
  margin-bottom: 8px;

  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    border-radius: 12px;
    background-color: var(--c-gray-600);
  }

  &.active {
    border-radius: 12px;
    color: var(--c-white);
    font-family: var(--f-subB);
    background-color: var(--c-gray-600);
  }
`;

const DropdownCon = styled.div`
  margin-top: 8px;
  position: absolute;
  left: 0;
  top: 100%;

  z-index: 99;

  min-width: 158px;
  border-radius: 12px;
  background-color: var(--c-gray-700);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);
`;

const DropdownUl = styled.ul`
  padding: 6px;
`;

const DropdownLi = styled.li`
  padding: 8px 16px;
  margin-bottom: 6px;

  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    border-radius: 12px;
    background-color: var(--c-gray-600);
  }

  &.active {
    border-radius: 12px;
    color: var(--c-white);
    font-family: var(--f-subB);
    background-color: var(--c-gray-600);
  }
`;

const MyMenu = () => {
  let menuItems = useMenu();
  const stateCheck = useStateCheck();
  const { openConfirm } = useConfirm();
  const { isMiddleD } = useMedia();
  const { pathname } = useLocation();
  const { isRefer } = useCommonState();

  const { ref: menuRef, isVisible: isMyMenu, setIsVisible: setIsMyMenu } = useOutsideBlur(false);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const isDrop = () => {
    setIsMyMenu(!isMyMenu);
  };

  const currentMenu = menuItems.find((menu) => {
    if (!menu.route) return false;

    return menu.route.some((route) => pathname.startsWith(route));
  });

  // 추천인이 없으면
  if (isRefer === 0) {
    menuItems = menuItems.filter((data) => {
      // 추천인 관리 없애기
      return data.key !== 2
    })
  }
  return (
    // 마이페이지 in MenuTree
    <Column $position={"relative"}>
      <Column $gap="16px" $pad={isMiddleD && "0 0 12px"} ref={menuRef} $width="fit-content">
        <SectionTitle name={t("myMenu.my_page")} />
        {!isMiddleD && (
          <DropWrapper onClick={isDrop}>
            <TextBox>{currentMenu.name}</TextBox>
            <MenuArrow width="20" height="20" />
          </DropWrapper>
        )}

        {!isMiddleD && isMyMenu && (
          <DropdownCon>
            <DropdownUl>
              {menuItems.map(({ name, key, route }) => (
                <DropdownLi
                  key={key}
                  onClick={() => (route ? stateCheck(route[0]) :
                    openConfirm({
                      subBtn: t("button.close"),
                      mainBtn: t("button.confirm"),
                      message: t("alert.islogout"),
                      Func: () => {
                        logout();
                      },
                    })
                  )}
                  className={isActiveMenuItem(route, pathname) ? "active" : ""}
                >
                  {name}
                </DropdownLi>
              ))}
            </DropdownUl>
          </DropdownCon>
        )}

        {isMiddleD && (
          <MyDropdownCon>
            <MyDropdownUl>
              {menuItems.map(({ name, key, route }) => (
                <MyDropdownLi
                  key={key}
                  onClick={() => (route ? stateCheck(route[0]) :
                    openConfirm({
                      subBtn: t("button.close"),
                      mainBtn: t("button.confirm"),
                      message: t("alert.islogout"),
                      Func: () => {
                        logout();
                      },
                    })
                  )}
                  className={isActiveMenuItem(route, pathname) ? "active" : ""}
                >
                  {name}
                </MyDropdownLi>
              ))}
            </MyDropdownUl>
          </MyDropdownCon>
        )}
      </Column>
    </Column>
  );
};

export default MyMenu;
