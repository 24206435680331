import { useTranslation } from "react-i18next";

const useGetEnum = () => {
  const { t } = useTranslation();

  const enums = {
    topBatting: {
      ALL: { label: t("state.all"), value: "", key: 1 },
      CASINO: { label: t("state.casino"), value: "C", key: 2 },
      SLOT: { label: t("state.slot"), value: "S", key: 3 },
    },
    sortOrder: {
      ALL: { label: "latest", value: "latest", key: 1 },
      TABLE: { label: "A-Z", value: "A-Z", key: 2 },
      SLOT: { label: "Z-A", value: "Z-A", key: 3 },
    },
    gameSelect: {
      ALL: { label: t("state.1X2_Gaming"), value: "1X2 Gaming", key: 1, count: 1 },
      TABLE: { label: t("state.amigogaming"), value: "amigogaming", key: 2, count: 2 },
      SLOT: { label: t("state.greentube"), value: "greentube", key: 3, count: 99 },
    },
    bettingState: {
      A: { label: t("placeholder.all_state"), value: "A", key: 0 },
      B: { label: t("state.betting"), value: "B", key: 1 },
      W: { label: t("state.winning"), value: "W", key: 2 },
      C: { label: t("state.cancel"), value: "C", key: 3 },
    },
    bettingPeriod: {
      TODAY: { label: t("state.today"), value: "TODAY", key: 1 },
      YESTERDAY: { label: t("state.yesterday"), value: "YESTERDAY", key: 2 },
      WEEK: { label: t("state.recent_week"), value: "WEEK", key: 3 },
      MONTH: { label: t("state.recent_month"), value: "MONTH", key: 4 },
    },
    moneyChangeState: {
      ALL: { label: t("state.all"), value: "", key: 1 },
      SUCCESS: { label: t("state.complete"), value: "COMPLETE", key: 2 },
      IMPOSSIBLE: { label: t("state.impossible"), value: "IMPOSSIBLE", key: 3 },
    },
    recentTables: [
      { label: "최근 배팅", value: "RECENT_BATTING", key: 1 },
      { label: "최근 당첨", value: "RECENT_PRIZE", key: 2 },
      // { label: "누적 롤링", value: "ACCRUE_ROLLING", key: 3 },
    ],
    qnaState: [
      { label: t("state.all"), value: "", key: 1 },
      { label: t("state.answer_complete"), value: "ANSWERED", key: 2 },
      { label: t("state.processing"), value: "PROCESSING", key: 3 },
    ],
    historyType: [
      { label: t("state.all_history"), value: "", key: 1 },
      { label: t("state.P"), value: "P", key: 2 },
      { label: t("state.R"), value: "R", key: 3 },
      { label: t("state.L"), value: "L", key: 4 },
      { label: t("state.MR"), value: "MR", key: 5 },
      { label: t("state.ML"), value: "ML", key: 6 },
      { label: t("state.U"), value: "U", key: 7 },
      { label: t("state.A"), value: "A", key: 8 },
      { label: t("state.J"), value: "J", key: 9 },
      { label: t("state.I"), value: "I", key: 10 },
      { label: t("state.F"), value: "F", key: 11 },
      { label: t("state.E"), value: "E", key: 12 },
    ],
    inoutState: [
      { label: t("state.all_type"), value: "", key: 1 },
      { label: t("state.in"), value: "IN", key: 2 },
      { label: t("state.out"), value: "OUT", key: 3 },
      { label: t("state.change"), value: "CHANGE", key: 4 },
    ],
    joinState: [
      { label: t("state.join_date"), value: "JOIN", key: 1 },
      { label: t("state.recent_access_date"), value: "LAST_LOGIN", key: 2 },
    ],
    compState: [
      { label: t("state.view_by_day"), value: "DAY", key: 1 },
      { label: t("state.view_by_month"), value: "MONTH", key: 2 },
    ],
    joinOrReferCode: [
      { label: t("common.join_code"), value: "JOIN", key: 1 },
      { label: t("common.refer_code"), value: "REFER", key: 2 },
    ],
    lngValue: [
      {
        title: t("language.ko"),
        value: "ko",
        key: 1,
      },
      {
        title: t("language.en"),
        value: "en",
        key: 2,
      },
    ],
  };

  return enums;
};

export default useGetEnum;
