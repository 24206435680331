import { TextBox } from "assets/Styles/SText";
import { Row } from "assets/Styles/custom/CustomLayout";
import { t } from "i18next";
import React from "react";

const NoSearch = () => {
  return (
    <Row $width="100%" $jus="center" $pad="20px 0" $mar="20px 0 0">
      <TextBox $class={["text", "gray300"]}>{t("infoMsg.search_no_data")}</TextBox>
    </Row>
  );
};

export default NoSearch;
