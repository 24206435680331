import React from "react";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import SwiperComponent from "components/hooks/SwiperComponent";

import { TextBox } from "assets/Styles/SText";
import { TabsForm, SlideForm } from "data/useForm";
import { SectionBox, Slide } from "assets/Styles/SLayout";
import { LinkIcon, MoneyIcon } from "assets/components/IconComponent";
import { CutWord } from "assets/Styles/custom/CustomText";
import { SectionTitle } from "components/common/useCommonStyle";
import { Between, Column, ContentBox, Row, Thumbnail } from "assets/Styles/custom/CustomLayout";

import { useMedia } from "util/useMedia";
import { unitFormat } from "util/Formatter";
import useGetEnum from "enum/useGetEnum";
import { useCommonState } from "context/useContext";
import { t } from "i18next";
import NoListLayout from "components/common/NoListLayout";
import { useStateCheck } from "util/useStateCheck";
import { useDispatch } from "react-redux";
import { loadingStatus } from "../../store/actions";
import { useAlert } from "context/useWindow";

const HighAmountGame = () => {
  const enums = useGetEnum();
  const dispatch = useDispatch();
  const stateCheck = useStateCheck();
  const { openAlert } = useAlert();

  const { setLoading, isLogin } = useCommonState();
  const { isMiddleM, isMaxMobile } = useMedia();

  const getAmountList = () => {
    dispatch(loadingStatus(true))
    const apiUrl = "/v1/game/best-win-game";
    api
      .get(apiUrl, {
        params: {
          ...useGame.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        useGame.setError("");
        useGame.setData(res.data.content);

        if (res.data.pageHelperDto) {
          useGame.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        useGame.setData([]);
        useGame.setError(true);
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
  };

  const errorAlert = () => {
    openAlert({
      title: t("alert.game_error_title"),
      message: t("alert.game_error"),
    });
  };

  const ApiCallback = (vendor, id) => {
    dispatch(loadingStatus(true))
    setLoading(true);

    const apiUrl = "/v1/user-game/game-launch-link";
    api
      .get(apiUrl, {
        params: {
          game_id: id,
          vendor: vendor,
        },
      })
      .then((res) => {
        window.open(res.data.content.link, '_blank');
      })
      .catch((error) => {
        console.error(error);
        errorAlert();
      }).finally(() => {
        setLoading(false);
        dispatch(loadingStatus(false));
      });
  }

  const useGame = useGetData({ firstInit: true, pageSize: "32", api: getAmountList });

  const slideData = () => {
    return useGame?.data?.map(({ vendor, id, title, maxWinMoney, thumbnail, is_inner_game, url }) => (
      <Slide className="h-a" key={vendor + id}>
        <Column
          $gap="6px"
          $align="center"
          $cursor="pointer"
          $width="fit-content"
          onClick={() => {
            is_inner_game == 'Y' ? stateCheck(`/${url}`) : stateCheck(`/game_zone/${vendor}/${id}`)
          }}
        >
          <Thumbnail $width={"82px"} src={thumbnail} $height={"82px"} $radius={"8px"} alt={vendor + id} />
          <Column $align="center" $width="100%">
            <Row $width="100%">
              <CutWord $maxWidth="69px" $tAlign="center" $class={["captionB", "white"]}>
                {title}
              </CutWord>
              <div onClick={(e) => {
                if (isLogin) {
                  e.stopPropagation();
                  ApiCallback(vendor, id)
                }
              }}>
                <LinkIcon
                  width="16"
                  height="16"
                />
              </div>
            </Row>
            <Row $align="center">
              <TextBox $class={["captionB", "purple"]}>{unitFormat(maxWinMoney)}</TextBox>
              <MoneyIcon width="14" height="14" fill="var(--c-purple)" />
            </Row>
          </Column>
        </Column>
      </Slide>
    ));
  };

  return (
    <SectionBox>
      <Between>
        <SectionTitle name={t("sectionTitle.best_win_games")} />
        {isMiddleM && (
          <SlideForm
            width="36px"
            radius="12px"
            height="36px"
            backColor="var(--c-gray-700)"
            prevEl="amount-prev-button"
            nextEl="amount-next-button"
          />
        )}
      </Between>
      <ContentBox $backColor="var(--c-gray-700)" $radius="12px">
        <Column $gap={isMaxMobile ? "12px" : "8px"} $pad="12px" $width="100%">
          <TabsForm
            textClass={["subB", "var(--c-gray-400)"]}
            backColor={"var(--c-gray-800)"}
            activeBg="var(--c-gray-600)"
            activeTc={["subB", "var(--c-white)"]}
            rowGap={"8px"}
            radius={"8px"}
            pad="4px 8px"
            options={enums.topBatting}
            {...useGame}
          />
          {useGame?.data?.length !== 0 ? (
            <SwiperComponent
              slidesPerView={"auto"}
              spaceBetween={12}
              slideData={slideData}
              useAutoplay={true}
              useNavigation={true}
              prevEl=".amount-prev-button"
              nextEl=".amount-next-button"
              initialSlide={0}
              delay={3000}
              centeredSlides={false}
              centeredSlidesBounds={false}
              loop={true}
            />
          ) : (
            <NoListLayout pad="52px 0" />
          )}
        </Column>
      </ContentBox>
    </SectionBox>
  );
};

export default HighAmountGame;
