export const getErrorClass = (errors, fieldName) => {
  return errors[fieldName] ? "not-value" : "";
};

export const validateField = (formData, fieldName, errorMessage, errors) => {
  if (formData[fieldName]?.length === 0) {
    errors[fieldName] = errorMessage;
    return false;
  }

  errors[fieldName] = undefined;
  return true;
};

export const focusField = (ref) => {
  if (ref.current) {
    ref.current.focus();
  }
};
