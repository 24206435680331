import { LoginCheck } from "util/useStateCheck";
const loginInfo = LoginCheck()

const checkPermission = () => {
    if (!('serviceWorker' in navigator)) {
        throw new Error("No support for service worker!")
    }

    if (!('Notification' in window)) {
        throw new Error("No support for notification API");
    }

    if (!('PushManager' in window)) {
        throw new Error("No support for Push API")
    }
}

const registerSW = async () => {
    // 로그인이 된 경우에만 ServiceWorker 등록
    if (loginInfo) {
        // ServiceWorker.js에 필요한 파라미터를 넘겨준다
        const params = new URLSearchParams({
            accessToken: loginInfo.accessToken,
            BackURL: process.env.REACT_APP_BASE_URL, 
            FrontURL: process.env.REACT_APP_OPTION === 'local' ? 'http://localhost:5200' : 'https://user.link-solution-test.com' 
        });
        // ServiceWorker 호출 및 등록
        const registration = await navigator.serviceWorker.register(`ServiceWorker.js?${params}`);
        return registration;
    }
}

const requestNotificationPermission = async () => {
    if (loginInfo) {
        const permission = await Notification.requestPermission();

        if (permission === 'default') { 
            Notification.requestPermission();
        } else if (permission === 'denied') {
            return false;
        }

        return true;
    }
}

const BrowserCheck = async () => {
    checkPermission()
    const notification = await requestNotificationPermission()
    notification && await registerSW()
}

export { BrowserCheck }