import styled from "styled-components";
import { TextBox } from "assets/Styles/SText";

const SideTitle = styled(TextBox)``;
const SideMenu = styled(TextBox)``;
const FilterTitle = styled(TextBox)`
  color: var(--c-gray-700);
  font-size: var(--s-caption);
  line-height: var(--l-caption);
`;

const NoSearchText = styled(TextBox)`
  color: var(--c-gray-700);
`;

const HoverTableText = styled(TextBox)`
  color: var(--c-mint-600);
  font-size: var(--s-table);
  cursor: pointer;
  position: relative;

  &:hover {
    text-decoration: underline;
  }
`;

const RecentTableMoney = styled(TextBox)`
  color: var(--c-blue);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-family: var(--f-subB);
  width: 100%;
  // max-width: 265px;

  @media (max-width: 700px) {
    max-width: 100%;
  }
  &.lost {
    color: var(--c-red);
  }
`;

const CutWord = styled(TextBox)`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  width: 100%;
  font-family: var(--f-subB);
  max-width: ${(props) => props.$maxWidth || "100%"};

  @media (max-width: 700px) {
    max-width: ${(props) => props.$maxWidth || "100%"};
  }
`;

export { SideMenu, SideTitle, FilterTitle, NoSearchText, HoverTableText, RecentTableMoney, CutWord };
