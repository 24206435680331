// CustomOption.js
import React, { useMemo } from "react";
import styled from "styled-components";
import { components } from "react-select";

import dropDownIcon from "assets/images/common/dropdownIcon.svg";
import check from "assets/images/common/check.svg";
import checkActive from "assets/images/common/check_a.svg";
import { Between, Row } from "assets/Styles/custom/CustomLayout";
import { TextBox } from "assets/Styles/SText";
import { CutWord } from "assets/Styles/custom/CustomText";
import { t } from "i18next";

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const SelectLength = styled(TextBox)`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-gray-700);
  border-radius: 8px;
  background-color: var(--c-purple);
`;

const Checkbox = styled.input`
  margin-right: 10px;
  min-width: 20px;
  min-height: 20px;
  background: url(${check}) no-repeat center;
  background-size: contain;
  appearance: none;
  border: none;
  outline: none;

  &:checked {
    background: url(${checkActive}) no-repeat center;
    background-size: contain;
  }
`;

const Number = styled.span`
  color: var(--c-purple);
`;

const CustomOption = ({ isSelected, label, data, addProvider, ...props }) => {
  const { count, value } = data;

  return (
    <components.Option {...props}>
      <OptionContainer>
        <Between $width="100%">
          <Row $gap="6px">
            <Checkbox type="checkbox" checked={isSelected} onChange={() => null} onClick={() => addProvider(value)} />
            <CutWord $color={isSelected ? "var(--c-white)" : "var(--c-gray-200)"} $maxWidth="72px">
              {label}
            </CutWord>
          </Row>
          <Number>{count}</Number>
        </Between>
      </OptionContainer>
    </components.Option>
  );
};

const CustomMenu = (props) => (
  <components.Menu {...props}>
    <div>
      <input type="text" placeholder="SearchCompo..." />
    </div>
    {props.children}
  </components.Menu>
);

const useCustomStyles = (pad, size, font, line, minWidth, height, minHeight) => {
  return useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        width: "100%",
        height: height || "40px",
        minWidth: minWidth || "158px",
        padding: pad || "0 12px",
        borderRadius: "12px",
        border: "0px",
        boxShadow: "none",
        alignItems: "center",
        minHeight: minHeight || "40px",
        gap: "4px",
        backgroundColor: "var(--c-gray-700)",
        whiteSpace: "nowrap",
        ":placeholder": {
          padding: "0",
        },
        ":hover": {
          borderColor: "var(--c-gray-300)",
        },
      }),
      option: (provided, { isSelected }) => ({
        ...provided,
        padding: "8px",
        margin: "12px 0",

        backgroundColor: "var(--c-gray-700)",
        color: isSelected ? "var(--c-white)" : "var(--c-gray-200)",
        cursor: "pointer",
        whiteSpace: "wrap",
        borderRadius: "12px",
        ":hover": { backgroundColor: "var(--c-gray-600)" },
        ":first-of-type": { margin: "0 0 6px" },
        ":last-of-type": { margin: "6px 0 0" },
      }),
      menu: (provided, { isSelected }) => ({
        ...provided,
        backgroundColor: isSelected ? "var(--c-gray-600)" : "var(--c-gray-700)",
        borderRadius: "12px",
        whiteSpace: "nowrap",
        padding: "6px",
        width: "100%",
        maxHeight: "400px",
        left: "0",
      }),
      dropdownIndicator: (provided, { isFocused }) => ({
        ...provided,
        background: `url(${dropDownIcon}) no-repeat center`,
        width: "18px",
        height: "18px",
        transition: "transform 0.3s ease",
        transform: isFocused ? "rotate(180deg)" : "none",
      }),
      input: (provided) => ({
        ...provided,
        color: "var(--c-gray-200)",
        margin: 0,
        padding: 0,
      }),
    }),
    [size, font, line]
  );
};

export default useCustomStyles;

const ValueContainer = ({ children, getValue, ...props }) => {
  const value = getValue();
  const count = value.length;

  let [values, input] = children;

  if (Array.isArray(values)) {
    values = (
      <>
        <TextBox>{t("common.vendor")}</TextBox>
        <SelectLength $class="captionB">{count}</SelectLength>
      </>
    );
  }

  return (
    <components.ValueContainer {...props}>
      {count > 0 ? (
        <Row $gap="6px" $align="center">
          {values}
          {input}
        </Row>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

const MultiValue = ({ children, getValue, ...props }) => {
  const value = getValue();
  const count = value.length;

  return (
    <>
      <Row $gap="6px" $align="center">
        <TextBox>{t("common.vendor")}</TextBox>
        <SelectLength $class="captionB">{count}</SelectLength>
      </Row>
    </>
  );
};

export { CustomOption, CustomMenu, useCustomStyles, MultiValue, ValueContainer };
