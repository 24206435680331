import { t } from "i18next";

const LngValue = [
  {
    title: t("language.ko"),
    value: "ko",
    key: 1,
  },
  {
    title: t("language.en"),
    value: "en",
    key: 2,
  },
];

export default LngValue;
