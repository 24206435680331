import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useLanguageChange } from "locales/useLanguageChange";
import { EventSourcePolyfill } from "event-source-polyfill";
import { match } from "path-to-regexp";
import { isEqual } from "lodash";
// hooks
import useRoutes from "./useRoutes";
import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";
import { Spinner } from "components/common/Spinner";
import { useDispatch, useSelector } from "react-redux";
// component
import Aside from "components/common/Aside";
import Header from "components/common/Header";
import Loading from "components/hooks/Loading";
import Inspection from "components/inspection/Inspection";
// style
import { Article, ContentWrap, MainLayout } from "assets/Styles/componentstyle/RouteStyle";

import { useAlert } from "context/useWindow";

import { clienInitialState, dataInitalState, loadingStatus, login } from "../store/actions";
import { t } from "i18next";
import api, { setLoginInfo } from "interceptor/api";
import useLogout from "components/hooks/useLogout";
import tokenApi from "interceptor/tokenApi";

const Routers = () => {
  useLanguageChange();
  const [inspection, setInspection] = useState(null);

  useEffect(() => {
    const sseUrl = `${process.env.REACT_APP_BASE_URL}/v1/site`;
    const sse = new EventSourcePolyfill(sseUrl);

    try {
      sse.addEventListener("update", (e) => {
        if (e?.data) {
          const newData = JSON.parse(e.data);
          if (!isEqual(newData, inspection)) {
            setInspection(newData);
          }
        }
      });

      sse.addEventListener("ping", (e) => {
        if (e?.data) {
          const pingData = JSON.parse(e.data);
        }
      });

      sse.onerror = () => {
        sse.close();
      };

      return () => {
        sse.close();
      };
    } catch (error) {
      console.error("Error initializing SSE:", error);
      sse.close();
    }
  }, []);

  if (!inspection) return;

  const isInspectionActive = (inspection) => {
    return (
      inspection.inspec_yn === "Y" ||
      (inspection.plan_inspec_yn === "Y" && inspection.plan_inspec_current_time_in_range)
    );
  };

  return isInspectionActive(inspection) ? (
    <Inspection inspection={inspection} />
  ) : (
    <Router>
      <RouteContent />
    </Router>
  );
};

const RouteContent = () => {
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const { loding } = useSelector((state) => state.Client);

  const { openAlert } = useAlert();
  const { routeCollection } = useRoutes();
  const { isDesktop, isMiddleM } = useMedia();
  const { isLogin, signUp } = useCommonState();
  const infoData = useSelector((state) => state.Data);

  const [menuSlide, setMenuSlide] = useState(isMiddleM && !isDesktop);
  const [logincheck, setLogincheck] = useState(false);
  const [menuList, setMenuList] = useState(null)

  var tempToken = null

  useEffect(() => {
    const handleResize = () => {
      const newMenuSlide = isMiddleM && !isDesktop;
      setMenuSlide(newMenuSlide);
    };

    handleResize(); // 초기 호출
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMiddleM, isDesktop]);

  const menuToggle = () => {
    setMenuSlide(!menuSlide);
  };

  useEffect(() => {
    const isPrivateRoute = routeCollection.some((route) => {
      if (!route.private) return false;
      const matchPath = match(route.path, { decode: decodeURIComponent });
      const result = matchPath(pathname.pathname);
      return result !== false;
    });

    if (infoData.state === "W" && pathname !== "/" && !pathname.includes("customer")) {
      navigate("/");
    }

    if (!isLogin && isPrivateRoute) {
      navigate("/");
    }
  }, [infoData.state, pathname]);

  const updateTableSubMenu = () => {
    dispatch(loadingStatus(true))
    const url = "/v1/game/side-menu";
    api
      .get(url)
      .then((res) => {
        const menus = res.data.content;
        if (!menus || menus.length === 0) {
          throw new Error("Menu data is empty or undefined.");
        }
        setMenuList(menus)
      })
      .catch((err) => {
        console.error("Error fetching menu:", err);
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
  };

  const checkAccessToken = async () => {
    if (!loding && !logincheck) {
      await vertifyAccessToken()
    }
    if (logincheck) {
      updateTableSubMenu()
    }
  }

  useEffect(() => {
    checkAccessToken()
  }, [logincheck]);

  const vertifyAccessToken = () => {
    const info = localStorage.getItem("info");
    if (!info) {
      setLogincheck(true)
      dispatch(loadingStatus(false))
      return
    }
    const { accessToken: myAccessToken, refreshToken: myRefreshToken } = JSON.parse(info).Client.token
    //토큰 존재 하지 않으면..
    if (!(myAccessToken)) {
      setLogincheck(true)
      dispatch(loadingStatus(false))
      return
    }

    tokenApi.post('/v1/user/vertify-access-token', null, { checkAccToken: true })
      .then((res) => {
        const { success } = res.data.content;
        if (success) {
          // setLoginInfo(tempToken)
          dispatch(loadingStatus(false)) // 로딩 상태 비활성화
          setLogincheck(true)
        }

      })
      .catch((err) => {
        let msg = ''
        switch (err?.response?.status) {
          case 401:
            vertifyRefreshToken(myRefreshToken)
            return
          case 403:
            msg = t("alert.forcing_logout")
            break;
          case 409:
            msg = t("alert.duplicate_login")
            break;
          default:
            msg = t("error.unknown")
            break;
        }
        openAlert({
          message: msg,
          Func: () => {
            localStorage.clear();
            dispatch(clienInitialState());
            dispatch(dataInitalState());
            window.location.href = "/";
          },
        });
      })
  }

  const vertifyRefreshToken = (myRefreshToken) => {
    tokenApi.post('/v1/auth/refresh-token', null, { checkRefToken: true })
      .then((res) => {
        const { accessToken, refreshToken } = res.data.content;

        if (accessToken) {
          const info = localStorage.getItem("info");
          const parseToken = JSON.parse(info).Client.token
          parseToken.accessToken = accessToken
          parseToken.refreshToken = refreshToken
          setLoginInfo(parseToken)
          dispatch(login({ "accessToken": accessToken, "refreshToken": refreshToken }));
          dispatch(loadingStatus(false)) // 로딩 상태 비활성화
          setLogincheck(true)
          return
        }

      })
      .catch((err) => {
        let msg = ''
        switch (err?.response?.status) {
          case 401:
            msg = t("alert.token_done")
            break;
          case 403:
            msg = t("alert.forcing_logout")
            break;
          case 409:
            msg = t("alert.duplicate_login")
            break;
          default:
            msg = t("error.unknown")
            break;
        }
        openAlert({
          message: msg,
          Func: () => {
            localStorage.clear();
            dispatch(clienInitialState());
            dispatch(dataInitalState());
            window.location.href = "/";
          },
        });
      })
  }

  if (!logincheck)
    return (
      <Spinner show={loding} />
    );


  return (
    <>
      <MainLayout className={menuSlide ? "slide" : ""}>
        <Spinner show={loding} />
        <ContentWrap>
          <Header menuSlide={menuSlide} menuToggle={menuToggle} menuList={menuList} />
          <Aside menuSlide={menuSlide} menuToggle={menuToggle} setMenuSlide={setMenuSlide} menuList={menuList} />
          <RouteContentSub />
        </ContentWrap>
      </MainLayout>
    </>
  );
};

const RouteContentSub = () => {
  const { routeCollection } = useRoutes();
  const { signUp } = useCommonState();

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Article>
          <Routes>
            {routeCollection.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  signUp === false && route.path !== "/sign_up" ? (
                    <Navigate to="/sign_up" />
                  ) : (
                    <route.component />
                  )
                }
              />
            ))}
          </Routes>
        </Article>
      </Suspense>
    </>
  );
};

export default Routers;
