import Main from "components/Main";
import GameZone from "components/game/GameZone";
import TableGame from "components/game/TableGame";

import MyPage from "components/my/MyPage";
import MyBettingList from "components/my/MyBettingList";

import Customer from "components/support/customer/Customer";
import CustomerFaQ from "components/support/customer/CustomerFaQ";

import Deposit from "components/my/deposit/Deposit";
import DepositList from "components/my/deposit/DepositList";

import PointConversion from "components/my/point/PointConversion";
import PointConversionList from "components/my/point/PointConversionList";

import Withdraw from "components/my/withdraw/Withdraw";
import WithdrawList from "components/my/withdraw/WithdrawList";

import Oauth from "components/sign/Oauth";
import Sign from "components/sign/Sign";
import SignUp from "components/sign/SignUp";
import Slot from "components/slot/Slot";

import QnaContact from "components/support/customer/QnaContact";
import CustomerQna from "components/support/customer/CustomerQna";
import QnaAnswer from "components/support/customer/QnaAnswer";

import MyNote from "components/my/note/MyNote";
import NoteContent from "components/my/note/NoteContent";
import ForYouGame from "components/game/ForYouGame";
import Notice from "components/support/notice/Notice";
import NoticeDetail from "components/support/notice/NoticeDetail";
import Event from "components/support/event/Event";
import EventDetail from "components/support/event/EventDetail";
import PointHistory from "components/my/point/PointHistory";
import MyRecommend from "components/my/recommend/MyRecommend";
import Id from "components/forgot/Id";
import Pwd from "components/forgot/Pwd";
import Nasdaq from "components/game/Nasdaq";
const useRoutes = () => {
  const routeCollection = [
    { path: "/oauth/redirect", component: Oauth },
    // 메인 컨텐츠
    { path: "/", component: Main },

    { path: "/sign", component: Sign },
    { path: "/sign_up", component: SignUp },

    { path: "/forgot_id", component: Id },
    { path: "/forgot_pwd", component: Pwd },

    // games

    { path: "/slot", component: Slot, private: true },
    { path: "/:menu_name/:menu_code", component: TableGame, private: true },
    { path: "/game_zone/:vendor/:id", component: GameZone, private: true },
    { path: "/FX/:ticker/:type", component: Nasdaq, private: true },

    { path: "/recent_games", component: ForYouGame, private: true },
    { path: "/new_games", component: ForYouGame, private: true },
    { path: "/popular_games", component: ForYouGame, private: true },
    { path: "/recommended_games", component: ForYouGame, private: true },

    // 입/출금
    { path: "/my.page", component: MyPage, private: true },

    { path: "/deposit", component: Deposit, private: true },
    { path: "/my.deposit", component: DepositList, private: true },

    { path: "/withdraw", component: Withdraw, private: true },
    { path: "/my.withdraw", component: WithdrawList, private: true },

    { path: "/point", component: PointConversion, private: true },
    { path: "/my.point", component: PointConversionList, private: true },
    { path: "/my.point_history", component: PointHistory, private: true },

    { path: "/my.betting", component: MyBettingList, private: true },
    { path: "/my.recommend", component: MyRecommend, private: true },

    { path: "/customer", component: Customer, private: true },
    { path: "/customer/customer_faq/:no", component: CustomerFaQ, private: true },

    { path: "/customer/qna", component: CustomerQna, private: true },
    { path: "/customer/qna/answer/:no", component: QnaAnswer, private: true },
    { path: "/customer/qna/contact", component: QnaContact, private: true },

    { path: "/note", component: MyNote, private: true },
    { path: "/note/:no", component: NoteContent, private: true },

    { path: "/notice", component: Notice, private: true },
    { path: "/notice/:no", component: NoticeDetail, private: true },

    { path: "/event", component: Event, private: true },
    { path: "/event/:no", component: EventDetail, private: true },
  ];

  return {
    routeCollection,
  };
};

export default useRoutes;
