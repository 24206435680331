import styled, { css } from "styled-components";
import { SwiperSlide } from "swiper/react";

const createFlexBox = ({
  $gap,
  $jus,
  $mar,
  $pad,
  $flex,
  $borB,
  $bTop,
  $over,
  $overX,
  $overY,
  $white,
  $align,
  $direct,
  $width,
  $shadow,
  $cursor,
  $height,
  $radius,
  $tAlign,
  $position,
  $flexWrap,
  $minWidth,
  $maxWidth,
  $maxHeight,
  $backColor,
}) => css`
  gap: ${$gap};
  flex: ${$flex};
  align-items: ${$align};
  flex-wrap: ${$flexWrap};
  justify-content: ${$jus};
  flex-direction: ${$direct};

  width: ${$width};
  min-width: ${$minWidth};
  max-width: ${$maxWidth};

  height: ${$height};
  max-height: ${$maxHeight};

  margin: ${$mar};
  padding: ${$pad};

  border-top: ${$bTop};
  border-bottom: ${$borB};
  border-radius: ${$radius};
  box-shadow: ${$shadow};

  text-align: ${$tAlign};
  background-color: ${$backColor};
  position: ${$position};

  overflow: ${$over};
  overflow-x: ${$overX};
  overflow-y: ${$overY};

  cursor: ${$cursor};
  white-space: ${$white};

  display: flex;
`;

const createModalBox = ({ $width }) => css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--c-white);

  width: ${$width ? $width : "280px"};
  padding: 16px 20px;
  border-radius: 4px;
  border: 1px solid var(--c-white);
`;

const createPadBox = ({ $pad, $width }) => css`
  padding: ${$pad};
  width: ${$width};
`;

const createIcon = ({ $url, $bor, $width, $height, $trans, $radius, $minWidth }) => css`
  display: block;
  border: ${$bor};
  width: ${$width};
  min-width: ${$minWidth};
  height: ${$height};
  background: ${`url(${$url}) no-repeat center`};
  border-radius: ${$radius};
  cursor: pointer;
  &.active {
    background: ${$url};
    transform: ${$trans};
  }
`;

const createPositionBox = ({
  $position,
  $top,
  $bottom,
  $trans,
  $left,
  $right,
  $zIndex,
  $width,
  $pad,
  $dis,
  $jus,
  $align,
  $height,
  $backColor,
}) => css`
  position: ${$position};
  top: ${$top};
  bottom: ${$bottom};
  left: ${$left};
  right: ${$right};
  transform: ${$trans};
  z-index: ${$zIndex};
  width: ${$width};
  padding: ${$pad};
  height: ${$height};
  display: ${$dis};
  align-items: ${$align};
  justify-content: ${$jus};
  background-color: ${$backColor ? $backColor : ""};
`;

const IconLink = styled.span`
  ${createIcon}
`;

const FlexBox = styled.div`
  ${createFlexBox}
`;

const PadBox = styled.div`
  ${createPadBox}
`;

const ModalBox = styled.div`
  ${createModalBox}
`;

const PositionBox = styled.div`
  ${createPositionBox}
`;

const ContentArea = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

const Dimmed = styled.div`
  background-color: var(--c-opacity);
  backdrop-filter: blur(var(--dim));
  position: fixed;
  top: 0;
  left: 0;
  padding: ${(props) => props.$pad};
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const SectionBox = styled.section.attrs({ className: "section" })`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Slide = styled(SwiperSlide)`
  width: ${(props) => (props.$width ? props.$width : "fit-content !important")};
  height: ${(props) => (props.$height ? props.$height : "fit-content !important")};
  max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : "fit-content !important")};
  &.h-a:hover {
    opacity: 0.4;
  }
`;

const Img = styled.img`
  width: ${(props) => props.$width};
  height: auto;
  cursor: pointer;
`;

const GameInfoBox = styled.div`
  width: 100%;
  padding: ${(props) => (props.$pad ? props.$pad : "14px 16px")};
`;

const GameInfoBoxTab = styled.div`
  display: flex;
  width: 100%;
  padding: ${(props) => (props.$pad ? props.$pad : "14px 9px 14px 16px")};
`;

const Empty = styled.div`
  text-align: ${(props) => props.$tAlign};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  padding: ${(props) => props.$pad};
`;

const createLineSection = ({ $pad, $borT, $borL, $borR, $borB, $width, $height, $radius }) => css`
  padding: ${$pad};
  border-top: ${$borT};
  border-left: ${$borL};
  border-right: ${$borR};
  border-bottom: ${$borB};
  border-radius: ${$radius};
  height: ${$height};

  width: ${$width ? $width : "fit-content"};
`;

const LineSection = styled.div`
  ${createLineSection}
`;

const createWarningBox = ({
  $dis,
  $gap,
  $jus,
  $mar,
  $pad,
  $flex,
  $align,
  $direct,
  $width,
  $shadow,
  $height,
  $radius,
  $tAlign,
  $flexWrap,
  $minWidth,
  $maxWidth,
  $maxHeight,
}) => css`
  gap: ${$gap};
  flex: ${$flex};
  display: ${$dis};
  align-items: ${$align};
  flex-wrap: ${$flexWrap};
  justify-content: ${$jus};
  flex-direction: ${$direct};

  width: ${$width};
  min-width: ${$minWidth};
  max-width: ${$maxWidth};

  height: ${$height};
  max-height: ${$maxHeight};

  margin: ${$mar};
  padding: ${$pad};

  box-shadow: ${$shadow};
  border-radius: ${$radius};

  text-align: ${$tAlign};
  background-color: var(--c-rOpacity);
`;

const WarningBox = styled.div`
  ${createWarningBox}
`;

const MyPageLayout = styled.div`
  width: 100%;
  max-width: ${(props) => props.$maxWidth ?? "500px"};
  padding-top: ${(props) => props.$padT ?? "40px"};
  padding: ${(props) => props.$pad};

  margin: 0 auto;
  overflow-x: ${(props) => props.$overX};

  display: ${(props) => props.$dis};
  align-items: ${(props) => props.$align};
  justify-content: ${(props) => props.$jus};

  @media (max-width: 1100px) {
    max-width: 100%;
    padding-top: 32px;
  }

  @media (max-width: 700px) {
    max-width: 100%;
  }
`;

const NotMenuLayout = styled.div`
  width: 100%;
  max-width: ${(props) => props.$maxWidth ?? "500px"};
  padding: ${(props) => props.$pad};

  margin: 0 auto;
  overflow-x: ${(props) => props.$overX};

  display: ${(props) => props.$dis};
  align-items: ${(props) => props.$align};
  justify-content: ${(props) => props.$jus};

  @media (max-width: 700px) {
    max-width: 100%;
  }
`;

const GrayCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap ?? "10px"};

  padding: ${(props) => props.$pad ?? "12px 16px"};
  border-radius: ${(props) => props.$radius ?? "12px"};
  background-color: var(--c-gray-700);

  align-items: ${(props) => props.$align};

  &.ho-ac {
    cursor: pointer;
    &:hover {
      background-color: var(--c-gray-600);
    }
  }
`;

const BorderBGrayCon = styled(GrayCon)`
  border-bottom: 1px solid var(--c-gray-600);
`;

export {
  ContentArea,
  //
  Img,
  IconLink,
  Slide,
  //
  Empty,
  Dimmed,
  //
  PadBox,
  FlexBox,
  ModalBox,
  WarningBox,
  SectionBox,
  PositionBox,
  GameInfoBox,
  GameInfoBoxTab,
  LineSection,
  //
  GrayCon,
  MyPageLayout,
  NotMenuLayout,
  BorderBGrayCon,
};
