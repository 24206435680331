import React from "react";

import { GrayCon } from "assets/Styles/SLayout";
import { TextBox } from "assets/Styles/SText";
import { t } from "i18next";

const NoListLayout = ({ pad }) => {
  return (
    <GrayCon $pad={pad ? pad : "140px 0"}>
      <TextBox $tAlign="center" $class={["sub", "gray300"]}>
        {t("infoMsg.no_data")}
      </TextBox>
    </GrayCon>
  );
};

export default NoListLayout;
