import React, { useState } from "react";

import { InputCon } from "assets/Styles/SInput";
import { Between, Column } from "assets/Styles/custom/CustomLayout";
import { PasswordIcon } from "assets/components/IconComponent";
import { P_GradientBtn } from "assets/Styles/SButton";
import { Empty } from "assets/Styles/SLayout";

import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";

import { InputForm } from "data/useForm";
import { useAlert, usePopup } from "context/useWindow";
import { getErrorClass } from "util/FormStateUtil";
import { useCommonState } from "context/useContext";

import api from "interceptor/api";
import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import { t } from "i18next";

const WithdrawPopup = ({ amount, openToast }) => {
  const { openAlert } = useAlert();
  const { inputError, setInputError } = useCommonState();
  const [passwordType, setPasswordType] = useState(false);
  const { closePopup } = usePopup();
  const dispatch = useDispatch();

  const postWithdraw = () => {
    dispatch(loadingStatus(true));
    const apiUrl = "/v1/account/out";

    api
      .post(apiUrl, {
        amount: amount,
        password: getValue.formValue.password,
      })
      .then((res) => {
        closePopup();
        openToast("/my.withdraw");

      })
      .catch((err) => {
        if (err.response.data.state == "p") {
          setInputError({
            password: t("error.wrong_withdraw_password"),
          });
        } else if (err.response.data.state == "t"){
          setInputError({ password: `${Number(err.response.data.data / 1000).toFixed()} ${t("error.wait_second_try")}` })
        }  
        // 출금 시간 확인
        else if (err.response.data.error == 'checkTime') {
          // 팝업 후 홈으로
          openAlert({
            message: t("alert.check_time"),
            Func: () => {
              window.location.reload();
            },
          });
        }
        // ~ 초 후에 다시 시도
        else {
          setInputError({ amount: `${Number(err.response.data / 1000).toFixed()} ${t("error.wait_second_try")}` })
        }

        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const isShowPassword = () => {
    setPasswordType(!passwordType);
  };

  const getValue = useFormData(
    {
      password: "",
    },
    postWithdraw
  );

  return (
    <Column $gap="20px">
      <Empty>
        <InputCon $pad="12px 16px" $radius="12px" className={getErrorClass(inputError, "password")}>
          <Between $gap="12px">
            <InputForm
              height={"20px"}
              type={passwordType ? "text" : "password"}
              name="password"
              backColor={"var(--c-gray-700)"}
              placeholder={t("placeholder.password")}
              errors={inputError}
              setErrors={setInputError}
              {...getValue}
            />
            <PasswordIcon
              onClick={isShowPassword}
              show={passwordType}
              width="20"
              height="20"
              fill="var(--c-gray-300)"
            />
          </Between>
        </InputCon>
        <Error name={"password"} errors={inputError} />
      </Empty>
      <P_GradientBtn
        $pad="12px 0"
        $radius="12px"
        onClick={postWithdraw}
        disabled={getValue.formValue.password.length === 0}
      >
        {t("button.confirm")}
      </P_GradientBtn>
    </Column>
  );
};

export default WithdrawPopup;
