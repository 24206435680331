import api from "interceptor/api";

export const postRequest = ({ url = "", body, Func, errorState, errorResponse }) => {
  let isError = false;

  if (errorState) {
    isError = errorState();
  }

  if (isError) {
    return;
  }

  api
    .post(url, body)
    .then((res) => {
      if (Func) {
        Func();
      }
    })
    .catch((err) => {
      const errorCodes = err.response.data.errors[0].msg.code;

      if (errorCodes) {
        errorResponse(errorCodes);
      }
      console.error(err);
    });
};
