import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { RequiredText, SuccessMsg, TextBox } from "assets/Styles/SText";
import { CheckInput, CheckRadio, InputCon } from "assets/Styles/SInput";
import { Empty, LineSection } from "assets/Styles/SLayout";
import { InputForm, SelectForm } from "data/useForm";
import { P_GradientBtn } from "assets/Styles/SButton";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";

import { useDispatch } from "react-redux";
import { loadingStatus } from "../../store/actions";

import { useMedia } from "util/useMedia";
import { useAlert } from "context/useWindow";
import { useCommonState } from "context/useContext";
import { getErrorClass, validateField } from "util/FormStateUtil";

import api from "interceptor/api";
import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import { t } from "i18next";
import { Timer } from "components/sign/Timer";
import { differenceBy } from "lodash";

const Content = styled.div`
  width: 100%;
`;

const options = [
  { label: "+82", value: "+82", key: 1 },
  { label: "+92", value: "+92", key: 2 }
];

const Id = () => {
  const phoneRegex = /^(01[0-9]|10[0-9])(\d{3,4})(\d{4})$/;
  const dispatch = useDispatch();

  const { openAlert } = useAlert();
  const { isMaxMiddleM } = useMedia();
  const { inputError, setInputError, signUp } = useCommonState();

  const [timerActiveOpt1, setTimerActiveOpt1] = useState(false);
  const [timerActiveOpt2, setTimerActiveOpt2] = useState(false);
  const [certifyResultOpt1, setCertifyResultOpt1] = useState(false);
  const [certifyResultOpt2, setCertifyResultOpt2] = useState(false);

  const [certifyNumOpt1, setCertifyNumOpt1] = useState(false);
  const [certifyNumOpt2, setCertifyNumOpt2] = useState(false);

  const getValue = useFormData({
    email: "",
    phone_number: "",
    certify1: "",
    certify2: "",
  });

  const [option, setOption] = useState('1');

  const certifyReset = () => {
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      certify1: "",
      certify2: "",
    }));
    setInputError((prevErrors) => ({
      ...prevErrors,
      certify1: "",
      certify2: "",
    }));
    if (option == 1) {
      setCertifyNumOpt1(true);
    } else {
      setCertifyNumOpt2(true);
    }
  };

  const alert = () => {
    if (option == 1) {
      setTimerActiveOpt1(false)
      setCertifyNumOpt1(false)
      setCertifyResultOpt1(false)
    } else {
      setTimerActiveOpt2(false)
      setCertifyNumOpt2(false)
      setCertifyResultOpt2(false)
    }
    openAlert({
      message: t("alert.auth_num_expired"),
      message2: t("alert.retry"),
      Func: async () => {
        certifyReset();
        await existAuth();
      }
    });
  };

  const certifyShow = async () => {
    await existAuth()
    setInputError((prevErrors) => ({
      ...prevErrors,
      phone_number: "",
    }));
  };

  let formValued = getValue.formValue;

  // 계정이 존재하고? 인증보내기
  const existAuth = () => {
    dispatch(loadingStatus(true));
    const apiUrl = option == 1 ? "/v1/auth/exist-phoneNo" : "/v1/auth/exist-email";
    api
      .post(apiUrl, {
        ...getValue.formValue,
      })
      .then((res) => {
        const { success } = res.data.content;
        if (success) {
          if (option == 1) {
            setCertifyNumOpt1(true);
            setTimerActiveOpt1(true);
          } else {
            setCertifyNumOpt2(true);
            setTimerActiveOpt2(true);
          }

        } else {
          openAlert({
            message: t("common.notice"),
            message2: t("alert.notExist"),
          });
        }
      })
      .catch((err) => {
        if (!err?.response || err?.response.status == 500) {
          openAlert({
            message: t("common.notice"),
            message2: t("error.unknown"),
          });
        } else if (err?.response.data.content.code == 'M001') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.blocked_member"),
          });
        } else if (err?.response.data.content.code == 'M002') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.leave_member"),
          });
        } else if (err?.response.data.content.code == 'M003') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.notExist_user"),
          });
        } else if (err?.response.data.content.code == 'M004') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.not_auth_email"),
          });
        } else if (err?.response.data.content.code == 'M005') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.sms_failed"),
          });
        } else if (err?.response.data.content.code == 'M009') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.wait_second_try"),
          });
        }
        if (option == 1) {
          setCertifyNumOpt1(false)
        } else {
          setCertifyNumOpt2(false)
        }
        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const findId = () => {
    dispatch(loadingStatus(true));
    const apiUrl = option == 1 ? "/v1/auth/find-id-phoneNo" : "/v1/auth/find-id-email";
    api
      .post(apiUrl, {
        ...getValue.formValue,
      })
      .then((res) => {
        // 아이디 표시
        const { success } = res.data.content;
        if (success) {
          openAlert({
            message: t("common.notice"),
            message2: t("alert.success_find_id"),
            Func: () => {
              window.location.href = "/";
            },
          });
        }
      })
      .catch((err) => {
        if (err?.response.data.content.code == 'M001') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.sms_failed"),
          });
        } else if (err?.response.data.content.code == 'M002') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.unknown"),
          });
        }
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };
  // 인증완료
  const doneAuth = () => {
    dispatch(loadingStatus(true));
    const apiUrl = option == 1 ? "/v1/auth/done-auth-phone" : "/v1/auth/done-auth-email";
    api
      .post(apiUrl, {
        ...getValue.formValue,
      })
      .then((res) => {
        const { success } = res.data.content;
        if (success) {
          if (option == 1) {
            setCertifyNumOpt1(false);
            setTimerActiveOpt1(true);
            setCertifyResultOpt1(true);
          } else {
            setCertifyNumOpt2(false);
            setTimerActiveOpt2(true);
            setCertifyResultOpt2(true);
          }
        } else {
          openAlert({
            message: t("common.notice"),
            message2: t("alert.notExist"),
          });
        }
      })
      .catch((err) => {
        if (option == 1) {
          setCertifyNumOpt1(true);
          setCertifyResultOpt1(false);
        } else {
          setCertifyNumOpt2(true);
          setCertifyResultOpt2(false);
        }
        if (err?.response.data.content.msg == 'M001') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.check_authCode"),
          });
        } else if (err?.response.data.content.msg == 'M002') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.timeout_authCode"),
          });
        }
        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };
  const blurValid = (name, errorMsg) => {
    const currentError = { ...inputError };
    const formValue = formValued;

    let getError = { ...currentError };

    validateField(formValue, name, errorMsg, getError);

    const validationMap = {
      phone_number: () => {
        if (formValue.phone_number.length === 0) {
          getError.phone_number = t("error.proceed_certification");
        } else if (!phoneRegex.test(formValue.phone_number)) {
          getError.phone_number = t("error.not_correct_cell_phone_number");
        }
      },

      email: () =>
        !/^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i.test(formValue.email) &&
        (getError.email = t("error.invalid_email")),
    };

    validationMap[name]?.();
    setInputError(getError);
  };

  // 인증완료
  const certifySuccess = async () => {
    await doneAuth()
  };

  useEffect(() => {
    setTimerActiveOpt1(false)
    setCertifyNumOpt1(false);
    setCertifyResultOpt1(false)
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      certify1: "",
    }));
  }, [getValue.formValue.country_code])

  useEffect(() => {
    setTimerActiveOpt1(false)
    setCertifyNumOpt1(false);
    setCertifyResultOpt1(false)
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      certify1: "",
    }));
  }, [getValue.formValue.phone_number])

  useEffect(() => {
    setTimerActiveOpt2(false)
    setCertifyNumOpt2(false);
    setCertifyResultOpt2(false)
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      certify2: "",
    }));
  }, [getValue.formValue.email])

  return (
    <Column $gap={isMaxMiddleM ? "16px" : "28px"} $align="center">
      <Column>
        <TextBox $tAlign="center" $class={[isMaxMiddleM ? "title" : "header", "white"]}>
          {t("button.id_find")}
        </TextBox>
        {signUp === false && <TextBox $pad="6px 0 0">{t("infoMsg.additional_info")}</TextBox>}
      </Column>

      <LineSection $width="100%" $borB="1px solid var(--c-gray-600)" />
      {/* 시작~ */}
      <Column $gap="32px" $align="center" $width="100%" $maxWidth="328px">

        <Column $gap="6px" $pad="0 3px" $align="left" $width="100%">
          {/* otp1 */}
          <Row $gap="6px" $pad="6px 3px" $align="center">
            <CheckRadio
              name="option"
              id="1"
              value={"1"}
              defaultChecked
              onChange={(e) => {
                setOption(e.target.value)
                setTimerActiveOpt2(false)
                setCertifyNumOpt2(false)
                setCertifyResultOpt2(false)
                getValue.setFormValue((prevValues) => ({
                  ...prevValues,
                  email: "",
                  certify2: "",
                }));
              }}
            />
            <TextBox as="label" htmlFor="un-read" $class={["white"]} for="1">
              {t("placeholder.forgot_id_opt1")}
            </TextBox>
          </Row>
          <TextBox as="label" htmlFor="un-read" $class={["white"]} for="1" $pad="0px 0px 3em">
            {t("placeholder.forgot_id_opt1_dtl")}
          </TextBox>
          {
            option == 1 && (

              <Content $width="100%" className="phone-sign-content">
                <RequiredText>{t("common.phone_num")}</RequiredText>
                {/* 국가번호 */}
                <SelectForm
                  name="country_code"
                  selectType="search"
                  placeholder={t("placeholder.country_num")}
                  conWidth="100%"
                  options={options}
                  errors={inputError}
                  setErrors={setInputError}
                  errorMsg={t("error.country_num_required")}
                  pad="12px 16px"
                  minWidth="100%"
                  size="var(--s-sub)"
                  height="fit-content"
                  minHeight="fit-content"
                  selectColor="var(--c-white)"
                  {...getValue}
                />
                <Row $gap="8px" $pad="8px 0 0">
                  <InputCon className={getErrorClass(inputError, "phone_number")} $pad="12px 16px" $radius="12px">
                    {/* 폰번호 */}
                    <InputForm
                      name="phone_number"
                      type="number"
                      placeholder={t("placeholder.no_hyphen_num")}
                      onBlur={() => blurValid("phone_number", t("error.phone_num_required"))}
                      {...getValue}
                      backColor="var(--c-gray-700)"
                    />
                  </InputCon>
                  {/* 인증 */}
                  <P_GradientBtn
                    onClick={certifyShow}
                    $radius="12px"
                    $width="71px"
                    $pad="12px 0"
                    disabled={!phoneRegex.test(formValued.phone_number) || certifyResultOpt1 || !formValued.country_code}
                  >
                    {t("button.certify")}
                  </P_GradientBtn>
                </Row>
                <Error errors={inputError} name={"phone_number"}></Error>
                <Row $gap="8px" $pad="8px 0 0">
                  <InputCon $pad="12px 16px" $radius="12px" className={getErrorClass(inputError, "certify1")}>
                    <Between>
                      {/* 인증번호 */}
                      <InputForm
                        name="certify1"
                        placeholder={t("placeholder.auth_num")}
                        {...getValue}
                        backColor="var(--c-gray-700)"
                        disabled={certifyResultOpt1 || !certifyNumOpt1}
                      />
                      {certifyNumOpt1 && !certifyResultOpt1 && (
                        <Timer alert={alert} reTimer={timerActiveOpt1} setReTimer={setTimerActiveOpt1} />
                      )}
                    </Between>
                  </InputCon>
                  {/* 인증완료 옵션1 */}
                  <P_GradientBtn
                    onClick={certifySuccess}
                    $radius="12px"
                    $width="71px"
                    $pad="12px 0"
                    disabled={!certifyNumOpt1}
                  >
                    {t("button.certify_complete")}
                  </P_GradientBtn>
                </Row>
                <Empty $pad="8px 0 0">
                  {inputError ? (
                    <Error padT="0" errors={inputError} name={"certify1"}></Error>
                  ) : (
                    <>
                      {!certifyResultOpt1 && certifyNumOpt1 && (
                        <TextBox $class={["caption", "gray400"]}>{t("infoMsg.certify_num_sent")}</TextBox>
                      )}
                      {certifyResultOpt1 && <SuccessMsg>{t("infoMsg.certify_complete")}</SuccessMsg>}
                    </>
                  )}
                </Empty>
              </Content>
            )
          }

          <LineSection $width="100%" $borB="1px solid var(--c-gray-600)" />
          {/* otp2 */}
          <Row $gap="6px" $pad="6px 3px" $align="center">
            <CheckRadio
              name="option"
              id="2"
              value={"2"}
              onChange={(e) => {
                setOption(e.target.value)
                setTimerActiveOpt1(false)
                setCertifyNumOpt1(false)
                setCertifyResultOpt1(false)
                getValue.setFormValue((prevValues) => ({
                  ...prevValues,
                  phone_number: "",
                  certify1: "",
                }));
              }}
            />
            <TextBox as="label" htmlFor="un-read" $class={["white"]} for="2">
              {t("placeholder.forgot_id_opt2")}
            </TextBox>
          </Row>
          <TextBox as="label" htmlFor="un-read" $class={["white"]} for="2" $pad="0px 0px 3em">
            {t("placeholder.forgot_id_opt2_dtl")}
          </TextBox>
          {option == 2 && (

            <Content $width="100%">
              <RequiredText>{t("common.email")}</RequiredText>
              <Row $gap="8px" $pad="8px 0 0">
                <InputCon
                  className={formValued.email.length === 0 ? "" : getErrorClass(inputError, "email")}
                  $radius="12px"
                  $pad="12px 16px"
                >
                  {/* 이메일 입력 */}
                  <InputForm
                    height={"20px"}
                    name="email"
                    backColor={"var(--c-gray-700)"}
                    placeholder={t("placeholder.test_email")}
                    errors={inputError}
                    setErrors={setInputError}
                    onBlur={formValued.email.length > 0 ? () => blurValid("email", t("error.invalid_email")) : undefined}
                    {...getValue}
                  />
                </InputCon>
                {/* 인증 버튼 */}
                <P_GradientBtn
                  onClick={certifyShow}
                  $radius="12px"
                  $width="71px"
                  $pad="12px 0"
                  disabled={
                    !/^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i.test(formValued.email)
                    || certifyResultOpt2}
                >
                  {t("button.certify")}
                </P_GradientBtn>
              </Row>

              <Row $gap="8px" $pad="8px 0 0">
                <InputCon $pad="12px 16px" $radius="12px" className={getErrorClass(inputError, "certify2")}>
                  <Between>
                    {/* 인증번호 입력 */}
                    <InputForm
                      name="certify2"
                      placeholder={t("placeholder.auth_num")}
                      {...getValue}
                      backColor="var(--c-gray-700)"
                      disabled={certifyResultOpt2 || !certifyNumOpt2}
                    />
                    {certifyNumOpt2 && !certifyResultOpt2 && (
                      <Timer alert={alert} reTimer={timerActiveOpt2} setReTimer={setTimerActiveOpt2} />
                    )}
                  </Between>
                </InputCon>
                {/* 인증 완료 옵션2 */}
                <P_GradientBtn
                  onClick={certifySuccess}
                  $radius="12px"
                  $width="71px"
                  $pad="12px 0"
                  disabled={!certifyNumOpt2}
                >
                  {t("button.certify_complete")}
                </P_GradientBtn>
              </Row>


              {formValued.email.length !== 0 && <Error errors={inputError} name={"email"}></Error>}

            </Content>
          )}

        </Column>

        <P_GradientBtn
          onClick={findId}
          $radius="12px"
          $pad="12px 0"
          disabled={!(certifyResultOpt1 || certifyResultOpt2)}
        >
          {t("button.id_find")}
        </P_GradientBtn>
      </Column>
    </Column>
  );
};

export default Id;
