import styled from "styled-components";
import { FlexBox } from "assets/Styles/SLayout";
import { TextBox } from "assets/Styles/SText";
import { Row, Column } from "../custom/CustomLayout";
const AsideLayOut = styled.aside`
  width: 262px;
  height: 100%;
  min-width: 262px;
  min-height: 100vh;
  padding: calc(env(safe-area-inset-top, 0px) + 20px) 20px 0
    calc(env(safe-area-inset-left, 0px) + 20px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: var(--c-gray-900);

  &.active {
    width: 97px;
    min-width: 97px;
    align-items: center;
  }

  @media (max-width: 700px) {
    display: none;
    &.active {
      width: 100%;
      min-width: 100%;
      align-items: center;
      z-index: 9999;
      padding: calc(env(safe-area-inset-top, 0px) + 16px)
        calc(env(safe-area-inset-right, 0px) + 16px) calc(env(safe-area-inset-bottom, 0px) + 16px)
        calc(env(safe-area-inset-left, 0px) + 16px);
      gap: 16px;
      display: block;
      overflow-y: auto;
    }
  }
`;

const MenuWrap = styled(Column)`
  gap: 32px;
  overflow-y: auto;
  overscroll-behavior: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;

  padding: 0 2px;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 700px) {
    &.active {
      height: 100%;
      padding: 0 0 40px;
    }
  }
`;

const GroupBox = styled(FlexBox)`
  position: relative;

  width: 100%;
  height: 46px;
  padding: 0 12px;
  border-radius: 24px;
  background-color: var(--c-gray-800);

  align-items: center;

  &.active {
    width: fit-content;
  }

  &.not-login {
    pointer-events: none;
  }

  &:hover {
    background-color: var(--c-gray-700);
    .group-title {
      color: var(--c-white);
    }
  }

  @media (max-width: 700px) {
    &.active {
      width: 100%;
    }
  }

  &.menu-slide {
    .menu-arrow {
      transform: rotate(-180deg);
    }
  }
`;

const GroupUL = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;

  position: relative;
  top: 0px;

  border-radius: 20px;
  background-color: var(--c-gray-800);

  overflow: hidden;

  @media (max-width: 700px) {
    &.menu-group.active {
      display: block !important;
    }
    width: 100%;
    justify-content: flex-end;
  }
`;

const GroupList = styled.li`
  cursor: pointer;
  height: 46px;
  padding: 0 12px;
  color: var(--c-gray-300);
  font-size: var(--s-sub);
  line-height: var(--l-sub);
  font-family: var(--f-subB);
  display: flex;
  align-items: center;
  gap: 12px;

  &:hover {
    & > span {
      color: var(--c-white);
    }
    background-color: var(--c-gray-700);
    border-radius: 24px;
  }

  &.active {
    & > span {
      color: var(--c-white);
    }
  }
`;

const MenuTitle = styled(TextBox)`
  font-size: var(--s-caption);
  line-height: var(--l-caption);
  font-family: var(--f-captionB);
  white-space: nowrap;
  color: var(--c-gray-300);

  @media (max-width: 700px) {
    text-align: left;
  }
`;

const GroupTitle = styled(TextBox)`
  &.active {
    display: none;
  }

  &.not-login {
    opacity: 0.4;
  }

  @media (max-width: 700px) {
    &.active {
      display: block;
    }
  }
`;

const MenuColumn = styled(Column)`
  &:last-child {
    padding-bottom: 20px;
  }

  @media (max-width: 700px) {
    align-items: flex-start;
  }
`;

const CloseIcon = styled.button`
  border-radius: 999px;
  background-color: var(--c-gray-800);
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;

  display: none;

  @media (max-width: 700px) {
    display: flex;
  }
`;

const NavMenu = styled(Row)`
  padding: 8px 0;
  @media (max-width: 700px) {
    padding: 0 0 16px;

    width: 100%;
    justify-content: flex-end;
  }
`;

const ArrowBox = styled.span`
  background-color: var(--c-hover);
  border-radius: 999px;

  width: 22px;
  height: 22px;
  cursor: pointer;

  &:hover {
    background-color: var(--c-gray-600);
  }

  @media (max-width: 700px) {
    &.menu-arrow.active {
      display: block !important;
    }
  }
`;

export {
  NavMenu,
  GroupUL,
  MenuWrap,
  GroupBox,
  ArrowBox,
  GroupList,
  CloseIcon,
  MenuTitle,
  MenuColumn,
  GroupTitle,
  AsideLayOut,
};
