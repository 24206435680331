import { combineReducers } from "redux"

import Client from "./client/reducer"
import Data from "./data/reducer"

const rootReducer = combineReducers({
  Client,
  Data
})

export default rootReducer
