import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useCommonState } from "context/useContext";
import { useDispatch } from "react-redux";
import { login } from "../../store/actions";

function Oauth() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { accessToken, refreshToken } = queryString.parse(search);
  const { setIsLogin } = useCommonState();

  // 서비스 워커가 등록 되어있다면 등록 해제
  const Unregister = async (accessToken, refreshToken) => {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const targetScope = process.env.REACT_APP_OPTION === 'local' ? 'http://localhost:5200' : 'https://user.link-solution-test.com';
    for (const registration of registrations) {
      if (registration.scope === `${targetScope}/`) {
        await registration.unregister();
      }
    }

    dispatch(login({ "accessToken": accessToken, "refreshToken": refreshToken }));
    return window.location.href = '/';
  }

  if (accessToken && refreshToken) {
    Unregister(accessToken, refreshToken)
    setIsLogin(true);
  } else {
    localStorage.clear();
    setIsLogin(false);

    return window.location.href = '/';
  }
}

export default Oauth;
