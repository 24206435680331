import { Row } from "assets/Styles/custom/CustomLayout";
import { PagingNextIcon, PagingPrevIcon } from "assets/components/IconComponent";
import React from "react";
import styled from "styled-components";

const PaginationNum = styled.button`
  color: var(--c-gray-200);
  font-size: var(--s-sub);
  font-family: var(--f-subB);

  height: 34px;
  padding: 7px 12px;
  border-radius: 12px;

  cursor: pointer;

  &:hover {
    background: var(--c-gray-700);
  }

  &.active {
    color: var(--c-white);
    background: var(--c-pGradient);

    pointer-events: none;
  }
`;

const PagingBtn = styled.button`
  width: 24px;
  height: 24px;

  &:disabled {
    opacity: 0.5;
  }
`;

const Pagination = ({ pagingInfo, onPageChange }) => {
  const pageChange = (page) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const generatePageNumbers = () => {
    const totalPages = pagingInfo?.totalPages;
    const current = pagingInfo?.current;
    const pagingNum = 5;
    const currentPageGroup = Math?.floor((current - 1) / pagingNum);

    const startPage = currentPageGroup * pagingNum + 1;
    const endPage = Math?.min(startPage + pagingNum - 1, totalPages);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <Row $gap="6px" $align="center" $jus="center">
      <PagingBtn
        onClick={() => pageChange(pagingInfo?.current - 1)}
        disabled={pagingInfo?.current === pagingInfo?.firstPage}
      >
        <PagingPrevIcon
          width="24"
          height="24"
          fill={pagingInfo?.current === pagingInfo?.firstPage ? "var(--c-gray-600)" : "var(--c-gray-200)"}
        />
      </PagingBtn>

      {pageNumbers.map((label) => (
        <PaginationNum
          key={label}
          className={label === pagingInfo?.current && "active"}
          onClick={() => pageChange(label)}
        >
          {label}
        </PaginationNum>
      ))}

      <PagingBtn
        onClick={() => pageChange(pagingInfo?.current + 1)}
        disabled={pagingInfo?.current === pagingInfo?.lastPage}
      >
        <PagingNextIcon
          width="24"
          height="24"
          fill={pagingInfo?.current === pagingInfo?.lastPage ? "var(--c-gray-600)" : "var(--c-gray-200)"}
        />
      </PagingBtn>
    </Row>
  );
};

export default Pagination;
