import styled from "styled-components";
import { useParams } from "react-router-dom";
import api from "interceptor/api";
import useGetData from "data/useGetData";
import { useDispatch } from "react-redux";
import { loadingStatus } from "../../store/actions";
import { useCommonState } from "context/useContext";
import { Row } from "assets/Styles/custom/CustomLayout";
import { useAlert } from "context/useWindow";
import { t } from "i18next";
import { LinkIcon } from "assets/components/IconComponent";

const Iframe = styled.iframe`
  width: 100% !important;
  border-radius: 12px;
`;

const DivForm = styled.div`
  padding: ${(props) => props.$pad ? props.$pad : '0'};
  text-align: ${(props) => props.$textAlign ? props.$textAlign : '' };
`

const GameZone = () => {
  const dispatch = useDispatch();
  const { id, vendor } = useParams();
  const { setLoading } = useCommonState();
  const { openAlert } = useAlert();

  const errorAlert = () => {
    openAlert({
      title: t("alert.game_error_title"),
      message: t("alert.game_error"),
      Func: () => { if(window.location.href.includes(id)) window.history.back();},
    });
  };
  
  const getGameLink = () => {
    dispatch(loadingStatus(true))
    setLoading(true);

    const apiUrl = "/v1/user-game/game-launch-link";
    api
      .get(apiUrl, {
        params: {
          game_id: id,
          vendor: vendor,
        },
      })
      .then((res) => {
        getLink.setData(res.data.content);
      })
      .catch((error) => {
        console.error(error);
        errorAlert();
      }).finally(() => {
        setLoading(false);
        dispatch(loadingStatus(false))
      });
  };

  const getLink = useGetData({
    firstInit: true,
    api: getGameLink,
  });

  const ApiCallback = (vendor, id) => {
    dispatch(loadingStatus(true))
    setLoading(true);

    const apiUrl = "/v1/user-game/game-launch-link";
    api
      .get(apiUrl, {
        params: {
          game_id: id,
          vendor: vendor,
        },
      })
      .then((res) => {
        window.open(res.data.content.link, '_blank');
      })
      .catch((error) => {
        console.error(error);
        errorAlert();
      }).finally(() => {
        setLoading(false);
        dispatch(loadingStatus(false))
      });
  }

  const useLink = getLink.data;

  return useLink ? (
    <>
      <DivForm $pad="0 0 6px 0" $textAlign="right" onClick={() => {ApiCallback(vendor, id)}}>
        <LinkIcon
          width="24" 
          height="24"
        />
      </DivForm>
      
      <Row $flex="1">
        <Iframe src={useLink?.link} />
      </Row>
    </>
  ) : null;
};

export default GameZone;
