import React from "react";

import useFormData from "data/useFormData";

import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";

import { TextBox } from "assets/Styles/SText";
import { Column, Linked, Row, RowLink } from "assets/Styles/custom/CustomLayout";
import { ContentSwap } from "assets/Styles/SButton";
import { GrayCon, FlexBox, MyPageLayout, NotMenuLayout } from "assets/Styles/SLayout";

import api from "interceptor/api";
import Pagination from "components/hooks/Pagination";
import useGetData from "data/useGetData";
import SearchCompo from "../../hooks/SearchCompo";
import NoSearch from "components/common/NoSearch";
import NoListLayout from "components/common/NoListLayout";
import { t } from "i18next";
import MyMenu from "components/my/layout/MyMenu";
import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";
import { Link } from 'react-router-dom';

const Customer = () => {
  const { isMaxMiddleM, isMiddleD } = useMedia();
  const { setLoading } = useCommonState();
  const dispatch = useDispatch();

  const getFaqInfo = () => {
    setLoading(true); 
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/user/faq";

    api
      .get(apiUrl, {
        params: {
          ...getValue.searchValue,
          ...getFaq.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getFaq.setData(res.data.content);

        if (res.data.pageHelperDto) {
          getFaq.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        getFaq.setData([]);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const getFaq = useGetData({
    firstInit: true,
    api: getFaqInfo,
    pageSize: "10",
  });

  const getData = getFaq.data;

  const getValue = useFormData(
    {
      value: "",
    },
    getFaqInfo,
    getFaq
  );

  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="20px">
          <Row $pad="6px" $radius="30px" $backColor="var(--c-gray-900)">
            <ContentSwap className="active">{t("sectionTitle.faq")}</ContentSwap>
            <ContentSwap as={Link} to="/customer/qna">{t("sectionTitle.one_to_one")}</ContentSwap>
          </Row>

          <SearchCompo
            page="10"
            name="value"
            getData={getFaq}
            apiFunc={getFaqInfo}
            placeholder={t("placeholder.search")}
            {...getValue}
          />

          {getData.length !== 0 && (
            <Column $gap="12px">
              {getData.map(({ no, title }) => (
                <Linked key={no} to={`/customer/customer_faq/${no}`}>
                  <GrayCon $radius="12px" $pad="14px 20px" className="ho-ac">
                    <Row $gap="14px" $align="flex-start">
                      <TextBox $class={["subB", "gray400"]}>Q</TextBox>
                      <TextBox $class={["subB", "white"]}>{title}</TextBox>
                    </Row>
                  </GrayCon>
                </Linked>
              ))}
            </Column>
          )}
          {getValue.searchValue.value && getData.length === 0 ? <NoSearch /> : getData.length === 0 && <NoListLayout />}
          {getData.length !== 0 && <Pagination {...getFaq} />}
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default Customer;
