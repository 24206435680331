import * as  TYPE from "./actionTypes"

export const update = (data) => {
    return {
        type: TYPE.UPDATE,
        alarm: data.is_alarm,
        total_unread_messages: data.total_unread_messages,
        money: data.money,
        point: data.point,
        state: data.state1
    }
}

export const updateMoney = (money) => {
    return {
        type: TYPE.UPDATE_MONEY,
        money: money,
    }
}

export const updateUnreadMessages = (total_unread_messages) => {
    return {
        type: TYPE.UNREAD_MESSAGES,
        total_unread_messages: total_unread_messages,
    }
}

export const alarmUpdate = (alarm) => {
    return {
        type: TYPE.ALARM,
        alarm: alarm,
    }
}

export const dataInitalState = () => {
    return {
        type: TYPE.INITIAL_STATE
    }
}