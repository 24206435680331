import React, { useEffect, useState } from "react";

import { TextBox } from "assets/Styles/SText";
import { InputCon } from "assets/Styles/SInput";
import { P_GradientBtn } from "assets/Styles/SButton";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";

import { patchRequest } from "apis/patchRequest";
import { Timer } from "components/sign/Timer";
import { useAlert } from "context/useWindow";
import { getErrorClass } from "util/FormStateUtil";
import { useCommonState } from "context/useContext";
import { InputForm } from "data/useForm";

import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";
import api from "interceptor/api";

const EmailPopup = ({ text, openToast, setToastMsg, closePopup, getProfileInfo, getData }) => {
  const { openAlert } = useAlert();
  //에러
  const { inputError, setInputError } = useCommonState();
  const dispatch = useDispatch();
  //인증
  const [certifyNum, setCertifyNum] = useState(false);
  //타이머
  const [timerActive, setTimerActive] = useState(false);

  const getValue = useFormData({
    email: getData.email,
    certify: "",
  });

  const certifyReset = () => {
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      certify: "",
    }));
    setCertifyNum(false);
  };

  const alert = () => {
    openAlert({
      message: t("common.notice"),
      message2: t("alert.auth_num_expired"),
      Func: () => certifyReset(),
    });
  };

  const existAuth = () => {
    dispatch(loadingStatus(true));
    const apiUrl = "/v1/user/email/auth";
    api
      .post(apiUrl, {
        ...getValue.formValue,
      })
      .then((res) => {
        const { success } = res.data.content;
        if (success) {
          setCertifyNum(true)
          setTimerActive(true)

        } else {
          openAlert({
            message: t("common.notice"),
            message2: t("alert.notExist"),
          });
        }
      })
      .catch((err) => {
        if (!err?.response || err?.response.status == 500) {
          openAlert({
            message: t("common.notice"),
            message2: t("error.unknown"),
          });
        } else if (err?.response.data.content.code == 'M003') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.created_email"),
          });
        } else if (err?.response.data.content.code == 'M004') {
          openAlert({
            message: t("common.notice"),
            message2: t("error.correct_email"),
          });
        }
        setCertifyNum(false);
        setTimerActive(false);
        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const errorState = () => {
    let hasError = false;

    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    if (!emailRegEx.test(getValue.formValue.email)) {
      setInputError((prev) => ({
        ...prev,
        email: t("error.correct_email"),
      }));
    }

    return hasError;
  };

  const errorResponse = (errorCodes) => {
    let errorMessage = "";
    setCertifyNum(false)
    switch (errorCodes) {
      case "M004":
        errorMessage = t("error.correct_email");
        break;

      // 중복 이메일
      case "M002":
        errorMessage = t("error.created_email");
        break;

      // 권한없음
      case "M001":
        errorMessage = t("error.check_authCode");
        break;

      default:
        errorMessage = t("error.check_authCode");
    }

    setInputError((prev) => ({
      ...prev,
      email: errorMessage,
    }));
  };

  const func = () => {
    closePopup();
    openToast();
    setToastMsg(t("toast.email_change", { text: text }));
    getProfileInfo();
  };

  const patchAction = () => {
    patchRequest({
      url: "/v1/user/email",
      params: {
        email: getValue.formValue.email,
        certify1: getValue.formValue.certify,
      },
      errorState: errorState,
      errorResponse: errorResponse,
      Func: func,
    });
  };

  const certifyShow = () => {
    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    if (!emailRegEx.test(getValue.formValue.email)) {
      setInputError((prev) => ({
        ...prev,
        email: t("error.correct_email"),
      }));
    } else {

      setCertifyNum(true);
      setTimerActive(true);
      existAuth()
    }

    setInputError((prev) => ({
      ...prev,
      certify: "",
    }));
  };

  const formValued = getValue.formValue;

  useEffect(() => {
    setTimerActive(false)
    setCertifyNum(false);
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      certify: "",
    }));
  }, [getValue.formValue.email])

  return (
    <>
      <Column $gap="8px" $width="100%" $pad="0 0 20px">
        <Row $gap="8px">
          <InputCon className={getErrorClass(inputError, "email")} $pad="12px 16px" $radius="12px">
            {/* 이메일 */}
            <InputForm
              name="email"
              placeholder={t("placeholder.email")}
              errors={inputError}
              setErrors={setInputError}
              backColor="var(--c-gray-700)"
              {...getValue}
            />
          </InputCon>
          {/* 인증 버튼 */}
          <P_GradientBtn
            onClick={certifyShow}
            $radius="12px"
            $width="71px"
            $pad="12px 0"
            disabled={
              !formValued.email
              || !/^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i.test(formValued.email)
              || !formValued?.email.length >= 5}
          >
            {t("button.certify")}
          </P_GradientBtn>
        </Row>
        <Error padT="0px" errors={inputError} name={"email"}></Error>
        <>
          <Row $gap="8px">
            <InputCon className={getErrorClass(inputError, "certify")} $pad="12px 16px" $radius="12px">
              <Between>
                <InputForm
                  name="certify"
                  placeholder={t("placeholder.auth_num")}
                  errors={inputError}
                  setErrors={setInputError}
                  disabled={!certifyNum}
                  {...getValue}
                  backColor="var(--c-gray-700)"
                />
                {certifyNum && <Timer alert={alert} reTimer={timerActive} setReTimer={setTimerActive} />}
              </Between>
            </InputCon>
          </Row>

          {certifyNum && inputError ? (
            <TextBox $class={["caption", "gray400"]}>{t("infoMsg.certify_num_sent")}</TextBox>
          ) : inputError ? (
            <Error padT="0px" errors={inputError} name={"certify"}></Error>
          ) : (
            ""
          )}
        </>
      </Column>
      <P_GradientBtn $pad="12px 0" $radius="12px" onClick={patchAction} disabled={formValued.certify.length !== 4 || !certifyNum}>
        {text}
      </P_GradientBtn>
    </>
  );
};

export default EmailPopup;
