import {
  CardIcon,
  BonusIcon,
  BestIcon,
  SlotIcon,
  CasinoIcon,
  TargetIcon,
  RecentIcon,
  ChessIcon,
  TrophyIcon,
  SpadeIcon,
  SicboIcon,
  RouletteIcon,
  RocketIcon,
  LuckIcon,
  LotteryIcon,
  JoystickIcon,
  GiftIcon,
  EightballIcon,
  ControllerIcon,
  HotIcon,
} from "assets/components/IconComponent";

export const getIconComponent = (icon) => {
  switch (icon) {
    case "side_bar_casino":
      return <CasinoIcon />;
    case "side_bar_bonus":
      return <BonusIcon />;
    case "side_bar_best":
      return <BestIcon />;
    case "side_bar_slot":
      return <SlotIcon />;
    case "side_bar_card":
      return <CardIcon />;
    case "side_bar_chess":
      return <ChessIcon />;
    case "side_bar_controller":
      return <ControllerIcon />;
    case "side_bar_eightball":
      return <EightballIcon />;
    case "side_bar_gift":
      return <GiftIcon />;
    case "side_bar_hot":
      return <HotIcon />;
    case "side_bar_joystick":
      return <JoystickIcon />;
    case "side_bar_lottery":
      return <LotteryIcon />;
    case "side_bar_luck":
      return <LuckIcon />;
    case "side_bar_recent":
      return <RecentIcon />;
    case "side_bar_rocket":
      return <RocketIcon />;
    case "side_bar_roulette":
      return <RouletteIcon />;
    case "side_bar_sicbo":
      return <SicboIcon />;
    case "side_bar_spade":
      return <SpadeIcon />;
    case "side_bar_target":
      return <TargetIcon />;
    case "side_bar_trophy":
      return <TrophyIcon />;
    default:
      return <CasinoIcon />;
  }
};
