import React, { useState } from "react";

import { SectionBox } from "assets/Styles/SLayout";
import { ContentBox } from "assets/Styles/custom/CustomLayout";
import { SectionTitle } from "components/common/useCommonStyle";
import { TabsForm } from "data/useForm";
import { useDispatch } from "react-redux";
import { loadingStatus } from "../../store/actions";
import { Table, TableDeps, RecentTableH, TableRow } from "assets/Styles/STable";
import { TextBox } from "assets/Styles/SText";
import { CutWord, RecentTableMoney } from "assets/Styles/custom/CustomText";
import { useMedia } from "util/useMedia";
import useGetEnum from "enum/useGetEnum";
import useGetData from "data/useGetData";
import api from "interceptor/api";
import { t } from "i18next";

const RecentTable = () => {
  const enums = useGetEnum();
  const dispatch = useDispatch();
  const { isMaxMiddleM } = useMedia();

  const getBatting = () => {
    dispatch(loadingStatus(true))
    const url = "/v1/game/recent-bet-list";
    api
      .get(url, { params: { 
        ...useGame.extendData.pagingData 
      } })
      .then((res) => {
        useGame.setData(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
  };
  
  const useGame = useGetData({
    firstInit: true,
    pageSize: "12",
    page: 0,
    typeData: "RECENT_BATTING",
    api: getBatting,
    // pageSize: "32",
  });
  return (
    <SectionBox>
      <SectionTitle name={t("sectionTitle.recent_bet")} />

      <TabsForm
        textClass={["var(--c-gray-400)", "subB"]}
        activeBg={"var(--c-gray-700)"}
        activeTc={["var(--c-white)", "subB"]}
        backColor={"var(--c-gray-900)"}
        rowGap={"8px"}
        radius={"50px"}
        pad="12px 20px"
        options={enums.recentTables}
        {...useGame}
      />

      <ContentBox $pad={isMaxMiddleM ? "20px" : "28px"} $radius="12px" $backColor="var(--c-gray-700)">
        <Table className="table-box" $tLayout="fixed" $width="100%">
          <thead>
            <tr className="table-title">
              <RecentTableH>
                <TextBox $width="100%" $tAlign="left">
                  {t("table.game")}
                </TextBox>
              </RecentTableH>
              {!isMaxMiddleM && (
                <>
                  <RecentTableH>
                    <TextBox $width="100%" $tAlign="left">
                      {t("table.vendor")}
                    </TextBox>
                  </RecentTableH>
                  <RecentTableH>
                    <TextBox $width="100%" $tAlign="center">
                      {t("table.player")}
                    </TextBox>
                  </RecentTableH>
                </>
              )}
              { useGame.extendData.pagingData.type === "RECENT_BATTING" ? (
                  <RecentTableH>
                    <TextBox $width="100%" $tAlign="right">
                      {t("table.bet_money")}
                    </TextBox>
                  </RecentTableH>
                ) : (
                  <RecentTableH>
                    <TextBox $width="100%" $tAlign="right">
                      {t("table.win_money")}
                    </TextBox>
                  </RecentTableH>
              ) }
            </tr>
          </thead>
          <tbody>
            {useGame.data &&
              useGame.data.map(({ money, nickname, provider, title }, index) => {
                const formattedMoney = Math.abs(money).toLocaleString();
                return (
                  <TableRow className="table-content" key={index}>
                    <TableDeps>
                      <CutWord $width="100%" $tAlign="left" $class={["white"]}>
                        {title}
                      </CutWord>
                    </TableDeps>
                    {!isMaxMiddleM && (
                      <>
                        <TableDeps>
                          <CutWord $tAlign="left">{provider}</CutWord>
                        </TableDeps>
                        <TableDeps>
                          <CutWord $tAlign="center">{nickname}</CutWord>
                        </TableDeps>
                      </>
                    )}
                    { useGame.extendData.pagingData.type === "RECENT_BATTING" ? (
                        <TableDeps>
                          <CutWord $tAlign="right" $class={["white"]}>
                            {formattedMoney}
                          </CutWord>
                        </TableDeps>
                      ) : (
                        <TableDeps>
                          <RecentTableMoney $tAlign="right">
                            {formattedMoney}
                          </RecentTableMoney>
                        </TableDeps>
                    )}
                  </TableRow>
                );
              })}
          </tbody>
        </Table>
      </ContentBox>
    </SectionBox>
  );
};

export default RecentTable;
