import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { loadingStatus } from "store/actions";

import { TextBox } from "assets/Styles/SText";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";
import { CheckIcon, MoneyIcon } from "assets/components/IconComponent";
import { ContentSwap } from "assets/Styles/SButton";
import { GrayCon, Empty, FlexBox, MyPageLayout } from "assets/Styles/SLayout";

import api from "interceptor/api";
import MyMenu from "../layout/MyMenu";
import useGetData from "data/useGetData";
import Pagination from "components/hooks/Pagination";
import useToast from "components/hooks/useToast";
import NoListLayout from "components/common/NoListLayout";

import { TabsForm } from "data/useForm";
import { useMedia } from "util/useMedia";
import { useConfirm } from "context/useWindow";
import { dateTimeFormat } from "util/DateUtil";
import useGetEnum from "enum/useGetEnum";
import { useCommonState } from "context/useContext";
import { deleteRequest } from "apis/deleteRequest";
import { t } from "i18next";
import { Link } from 'react-router-dom';

const DepositList = () => {
  const enums = useGetEnum();
  const dispatch = useDispatch();
  const infoData = useSelector(state => state.Data);

  const { isMiddleD } = useMedia();

  const { openConfirm } = useConfirm();
  const { setLoading } = useCommonState();
  const { openToast, toastOpen, Toast } = useToast();
  const [getContent, setGetContent] = useState([]);

  const getAccountList = () => {
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/account/out/log";

    api
      .get(apiUrl, {
        params: {
          ...getAccount.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getAccount.setData(res.data);
        setGetContent(res.data.userHistoryAccountList);
        if (res.data) {
          getAccount.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        getAccount.setData([]);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const getAccount = useGetData({
    firstInit: true,
    pageSize: "20",
    api: getAccountList,
  });

  const withdrawCancel = (no) => {
    openConfirm({
      subBtn: t("button.close"),
      mainBtn: t("button.confirm"),
      message: t("button.cancel_request"),
      Func: () => {
        openToast();
        deleteAction(no);
      },
    });
  };

  const deleteAction = (no) => {
    deleteRequest({
      Func: getAccountList,
      url: `/v1/account/out/cancel`,
      params: {
        no: no,
      },
    });
  };

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="24px">
          <Row $pad="6px" $radius="30px" $backColor="var(--c-gray-900)">
            <ContentSwap as={Link} to="/withdraw">{t("common.withdraw")}</ContentSwap>
            <ContentSwap className="active">{t("sectionTitle.withdraw_history")}</ContentSwap>
          </Row>

          <GrayCon $align="center" $pad="16px 0">
            <TextBox>{t("common.money")}</TextBox>
            <Row $gap="2px" $align="center">
              <TextBox $class={["white", "header"]}>{infoData.money.toLocaleString()}</TextBox>
              <MoneyIcon width="22" height="22" fill="var(--c-white)" />
            </Row>
          </GrayCon>

          <Column $gap="12px">
            <TabsForm
              pad="10px 16px"
              radius="20px"
              textClass={["subB", "gray400"]}
              activeTc={["subB", "white"]}
              activeBg={"var(--c-gray-700)"}
              backColor={"var(--c-gray-900)"}
              options={enums.moneyChangeState}
              {...getAccount}
            />
            {getContent.length !== 0 ? (
              <>
                {getContent &&
                  getContent.map(({ no, state, amount, create_at, process_at }) => {
                    let depositState = "";
                    let successState = "";
                    let stateStyle = [];

                    switch (state) {
                      case "P":
                        depositState = t("state.complete");
                        successState = t("state.process");
                        stateStyle = ["sub", "blue"];
                        break;
                      case "D":
                        depositState = t("state.waiting");
                        successState = t("state.request");
                        stateStyle = ["sub"];
                        break;
                      case "B":
                        depositState = t("state.impossible");
                        successState = t("state.process");
                        stateStyle = ["sub", "red"];
                        break;

                      default:
                        depositState = "";
                        successState = "";
                        stateStyle = [];
                    }

                    return (
                      <GrayCon key={no}>
                        <Between $align="flex-end !important">
                          <Empty>
                            <TextBox $pad="0 0 4px" $class={stateStyle}>
                              {depositState}
                            </TextBox>
                            <Row $gap="2px" $align="center">
                              <TextBox $class={"subB"}>-{amount?.toLocaleString()}</TextBox>
                              <MoneyIcon width="16" height="16" fill="var(--c-gray-200)" />
                            </Row>
                          </Empty>
                          <Empty>
                            {state === "D" && (
                              <Row $width="100%" $jus="flex-end">
                                <TextBox
                                  as="button"
                                  onClick={() => withdrawCancel(no)}
                                  $dis="block"
                                  $width="fit-content"
                                  $pad="0 0 4px"
                                  $white="nowrap"
                                  $class={["sub", "red"]}
                                  $textDeco="underline"
                                >
                                  {t("button.call_off")}
                                </TextBox>
                              </Row>
                            )}
                            <TextBox $dis="block" $width="100%" $white="nowrap" $class={["sub", "gray300"]}>
                              {dateTimeFormat(process_at ? process_at : create_at)} &nbsp;
                              {successState}
                            </TextBox>
                          </Empty>
                        </Between>
                      </GrayCon>
                    );
                  })}
              </>
            ) : (
              <NoListLayout />
            )}
          </Column>
          {getContent.length !== 0 && <Pagination {...getAccount} />}
        </Column>
        {toastOpen && (
          <Toast Icon={<CheckIcon />} toastText={t("toast.request_cancel", { action: t("common.withdraw") })} />
        )}
      </MyPageLayout>
    </FlexBox>
  );
};

export default DepositList;
