import React from "react";
import { Empty, FlexBox, SectionBox, Slide } from "assets/Styles/SLayout";
import { ContentBox, Thumbnail } from "assets/Styles/custom/CustomLayout";
import { useMedia } from "util/useMedia";

import SwiperComponent from "components/hooks/SwiperComponent";
import { TextBox } from "assets/Styles/SText";
import { P_GradientBtn } from "assets/Styles/SButton";

import slide_banner1 from "assets/images/common/slide_banner1.png";
import slide_banner2 from "assets/images/common/slide_banner2.png";
import slide_banner3 from "assets/images/common/slide_banner3.png";

import banner from "assets/images/common/banner.png";
import banner1680 from "assets/images/common/banner1680.png";

import { useDispatch, useSelector } from "react-redux";
import { loadingStatus } from "../../store/actions";
import { Link, useNavigate } from 'react-router-dom';
import { useCommonState } from "context/useContext";
import { t } from "i18next";
import styled from "styled-components";
import api from "interceptor/api";
import useGetData from "data/useGetData";
import { useAlert, usePopup } from "context/useWindow";
import Login from "components/popup/Login";


//없어질 더미데이터 다국어 적용 x
const items = [
  { key: 1, thumbnail: slide_banner1 },
  { key: 2, thumbnail: slide_banner2 },
  { key: 3, thumbnail: slide_banner3 },
  { key: 4, thumbnail: slide_banner2 },
  { key: 5, thumbnail: slide_banner1 },
  { key: 6, thumbnail: slide_banner3 },
];

const StyledContentBox = styled(ContentBox)`
  overflow: hidden;
  position: relative;
  height: 100%;

  & > .swiper {
    overflow: visible;
    height: 100%;
  }
  .swiper-pagination {
    padding-top: 16px;
  }
`;

const SwiperThumbnail = styled(Thumbnail)`
  height: 100%;
  max-height: 376px;
  aspect-ratio: 16 / 9;
`;

const Banner = () => {
  const { isBanner, isMaxTablet, isMaxMiddleM, isMaxMiddleD } = useMedia();
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const infoData = useSelector(state => state.Data)
  const { openAlert } = useAlert();
  const { openPopup } = usePopup();
  const { isLogin } = useCommonState();

  const showPopup = () => {
    openPopup({
      content: Login,
      title: t("sectionTitle.login"),
    });
  };

  const showAlert = () => {
    openAlert({
      title: t("alert.join_wait"),
      message: t("alert.join_wait_message"),
    });
  };
  const getBannerApi = () => {
    dispatch(loadingStatus(true))
    const apiUrl = "/v1/site/banner";
    api
      .get(apiUrl)
      .then((res) => {
        getBannerData.setData(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
  };

  const getBannerData = useGetData({
    api: getBannerApi,
    firstInit: true,
  });

  const getBannerImg = getBannerData.data;

  const getHref = (link, link_type) => {
    if (!link || link_type === "G") {
      return "#";
    }
    const cleanLink = link.replace(/^(https?:\/\/)/, "");

    if (cleanLink.startsWith("www.") || !cleanLink.includes(".")) {
      return `https://${cleanLink}`;
    }

    return `https://${cleanLink}`;
  };

  const stateChecks = (e, link, vendor, link_type, id) => {

    if (!isLogin) {
      showPopup();
    } else if (infoData.state === "W") {
      showAlert();
    } else if (link_type === "G") {
      navigate(`/game_zone/${vendor}/${id}`);
    } else if (link) {
      if (!link.startsWith("/")) {
        // 외부 링크
        window.location.href = getHref(link);
      } else {
        // 내부 링크
        navigate(link)
      }
    }
  };

  const banners = getBannerImg || [];
  const slidesPerView = isMaxTablet ? 2 : 3;
  const slideData = () => {
    return (
      <>
        {banners.map(({ no, banner_no, img_uuid, link_type, link, vendor, id }) => (
          <Slide key={no} $height={"fit-content"} $maxWidth="100%">
            <ContentBox
              as={Link}
              onClick={(e) => stateChecks(e, link, vendor, link_type, id)}
              to={getHref(link, link_type)}
              $cursor={link_type && "pointer"}
              $maxWidth={isMaxMiddleM ? "100%" : "668px"}
              $radius="12px"
            >
              <SwiperThumbnail
                $radius="12px"
                $maxWidth={isMaxMiddleM ? "100%" : "668px"}
                $width="100%"
                $height={"fit-content"}
                src={process.env.REACT_APP_IMG_URL + img_uuid}
                alt={`Banner ${banner_no}`}
              />
            </ContentBox>
          </Slide>
        ))}
      </>
    );
  };

  return (
    <SectionBox>
      {isMaxMiddleM && getBannerImg ? (
        <SwiperComponent
          swiperClass="banner-swiper"
          delay={15000}
          slideData={slideData}
          slidesPerGroup={1}
          slidesPerView={1}
          useAutoplay={true}
          usePagination={true}
          useNavigation={true}
          loopedSlides={1}
          loop={true}
        />
      ) : (
        <>
          <ContentBox $radius="12px" $backColor="var(--c-gray-700)" $height={isMaxMiddleD ? "100%" : "346px"}>
            <FlexBox
              $width="100%"
              $align="center"
              $over="hidden"
              $pad={isMaxMiddleD && "60px 0"}
              $jus={!isMaxMiddleD && "space-evenly"}
              $direct={isMaxMiddleD ? "column-reverse" : "row"}
              $gap={isMaxMiddleD && "40px"}
            >
              <Empty $tAlign={isMaxMiddleD && "center"}>
                <Empty $tAlign={isMaxMiddleD && "center"} $pad="0 0 24px">
                  <TextBox $white="nowrap" $class={["header", "white"]} $pad="0 0 8px" $dis="block">
                    {t("common.join_reward")}
                  </TextBox>
                  <TextBox $white="nowrap" $class={["60", "purple"]}>
                    US$20,000
                  </TextBox>
                </Empty>
                <P_GradientBtn
                  $width="fit-content"
                  $pad="16px 50px"
                  $radius="27px"
                  onClick={() => stateChecks("/deposit")}
                >
                  {isLogin ? t("button.deposit_play") : t("button.login_again")}
                </P_GradientBtn>
              </Empty>

              <Thumbnail $width={isBanner ? "500px" : "810px"} src={isBanner ? banner1680 : banner} alt="banner" />
            </FlexBox>
          </ContentBox>
          {getBannerImg && (
            <StyledContentBox>
              <SwiperComponent
                swiperClass="banner-swiper"
                delay={3500}
                spaceBetween={16}
                slideData={slideData}
                slidesPerGroup={1}
                slidesPerView={slidesPerView}
                useAutoplay={true}
                usePagination={true}
                useNavigation={true}
                loop={true}
                speed={800}
              />
            </StyledContentBox>
          )}
        </>
      )}
    </SectionBox>
  );
};

export default Banner;
