import React from "react";
import { useParams } from "react-router-dom";

import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";

import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";

import { TextBox } from "assets/Styles/SText";
import { PrevIcon } from "assets/components/IconComponent";
import { Column, RowLink } from "assets/Styles/custom/CustomLayout";
import { GrayCon, FlexBox, MyPageLayout, LineSection } from "assets/Styles/SLayout";

import api from "interceptor/api";
import MyMenu from "../../my/layout/MyMenu";
import useGetData from "data/useGetData";
import { t } from "i18next";

const CustomerFaQ = () => {
  const { no } = useParams();
  const { isMiddleD, isMaxMiddleM } = useMedia();
  const { setLoading } = useCommonState();
  const dispatch = useDispatch();
  
  const getCustomerInfo = () => {
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/user/faq/content";

    api
      .get(apiUrl, {
        params: {
          no: no,
        },
      })
      .then((res) => {
        getCustomer.setData(res.data.content);
        setLoading(false);
        getCustomer.setError("");
      })
      .catch((err) => {
        console.error(err);
        getCustomer.setError(true);
        setLoading(false);
        getCustomer.setData([]);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const getCustomer = useGetData({
    firstInit: true,
    api: getCustomerInfo,
  });

  const getData = getCustomer.data;

  if (!getData) return;
  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="24px">
          <RowLink to="/customer" $gap="4px">
            <PrevIcon width="16" height="16" fill="var(--c-gray-300)" />
            <TextBox $class="gray300">{t("button.return_list")}</TextBox>
          </RowLink>

          {getData.length !== 0 && (
            <GrayCon $radius="12px" $pad={isMaxMiddleM ? "20px" : "24px 28px"}>
              <Column $gap="20px" $align="left">
                <TextBox $class={[isMaxMiddleM ? "title" : "header", "white"]}>{getData.title}</TextBox>

                <LineSection $width="100%" $borB="1px solid var(--c-gray-500)" />
                <TextBox $class="white" dangerouslySetInnerHTML={{ __html: getData.contents }} />
              </Column>
            </GrayCon>
          )}
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default CustomerFaQ;
